/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Region,
} from '../models/index';
import {
    RegionFromJSON,
    RegionToJSON,
} from '../models/index';

export interface OebbSitesRequest {
    berichtsFilter?: string;
}

/**
 * 
 */
export class MasterDataApi extends runtime.BaseAPI {

    /**
     * Gibt eine Liste aller Maschinengruppen zurück.
     * Gibt eine Liste aller Maschinengruppen zurück.
     */
    async maschinengruppenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/machineGroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gibt eine Liste aller Maschinengruppen zurück.
     * Gibt eine Liste aller Maschinengruppen zurück.
     */
    async maschinengruppen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.maschinengruppenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste aller ASCs zurück
     */
    async oebbSitesRaw(requestParameters: OebbSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.berichtsFilter !== undefined) {
            queryParameters['BerichtsFilter'] = requestParameters.berichtsFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/oebbSites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gibt eine Liste aller ASCs zurück
     */
    async oebbSites(requestParameters: OebbSitesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.oebbSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste aller Regionen mit jeweiligen Regionalleitungen zurück.
     * Gibt eine Liste aller Regionen mit jeweiligen Regionalleitungen zurück.
     */
    async regionenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Region>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/regions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegionFromJSON));
    }

    /**
     * Gibt eine Liste aller Regionen mit jeweiligen Regionalleitungen zurück.
     * Gibt eine Liste aller Regionen mit jeweiligen Regionalleitungen zurück.
     */
    async regionen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Region>> {
        const response = await this.regionenRaw(initOverrides);
        return await response.value();
    }

}
