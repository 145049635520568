/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DbbgEinsatzOperationTyp } from './DbbgEinsatzOperationTyp';
import {
    DbbgEinsatzOperationTypFromJSON,
    DbbgEinsatzOperationTypFromJSONTyped,
    DbbgEinsatzOperationTypToJSON,
} from './DbbgEinsatzOperationTyp';

/**
 * 
 * @export
 * @interface DbbgEinsatzOperation
 */
export interface DbbgEinsatzOperation {
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzOperation
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzOperation
     */
    railiumEinsatzId: number;
    /**
     * 
     * @type {DbbgEinsatzOperationTyp}
     * @memberof DbbgEinsatzOperation
     */
    typ: DbbgEinsatzOperationTyp;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzOperation
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzOperation
     */
    bis: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzOperation
     */
    bemerkung: string;
}

/**
 * Check if a given object implements the DbbgEinsatzOperation interface.
 */
export function instanceOfDbbgEinsatzOperation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "railiumEinsatzId" in value;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "bemerkung" in value;

    return isInstance;
}

export function DbbgEinsatzOperationFromJSON(json: any): DbbgEinsatzOperation {
    return DbbgEinsatzOperationFromJSONTyped(json, false);
}

export function DbbgEinsatzOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'railiumEinsatzId': json['RailiumEinsatzId'],
        'typ': DbbgEinsatzOperationTypFromJSON(json['Typ']),
        'von': json['Von'],
        'bis': json['Bis'],
        'bemerkung': json['Bemerkung'],
    };
}

export function DbbgEinsatzOperationToJSON(value?: DbbgEinsatzOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'RailiumEinsatzId': value.railiumEinsatzId,
        'Typ': DbbgEinsatzOperationTypToJSON(value.typ),
        'Von': value.von,
        'Bis': value.bis,
        'Bemerkung': value.bemerkung,
    };
}

