/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContractPosition,
  ContractPositionCategory,
  PoolContractPosition,
} from '../models/index';
import {
    ContractPositionFromJSON,
    ContractPositionToJSON,
    ContractPositionCategoryFromJSON,
    ContractPositionCategoryToJSON,
    PoolContractPositionFromJSON,
    PoolContractPositionToJSON,
} from '../models/index';

export interface CreateOrUpdatePositionRequest {
    contractId: number;
    positionId: number;
    contractPosition: ContractPosition;
}

export interface CreatePoolPositionRequest {
    poolContractPosition: PoolContractPosition;
}

export interface DeletePoolPositionRequest {
    positionId: number;
}

export interface DeletePositionRequest {
    contractId: number;
    positionId: number;
}

export interface GetPoolPositionsRequest {
    categoryId?: number;
}

export interface GetUsedPositionsRequest {
    contractId: number;
}

export interface UpdatePoolPositionRequest {
    positionId: number;
    poolContractPosition: PoolContractPosition;
}

/**
 * 
 */
export class PositionsApi extends runtime.BaseAPI {

    /**
     */
    async createOrUpdatePositionRaw(requestParameters: CreateOrUpdatePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling createOrUpdatePosition.');
        }

        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling createOrUpdatePosition.');
        }

        if (requestParameters.contractPosition === null || requestParameters.contractPosition === undefined) {
            throw new runtime.RequiredError('contractPosition','Required parameter requestParameters.contractPosition was null or undefined when calling createOrUpdatePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/used/{contractId}/{positionId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))).replace(`{${"positionId"}}`, encodeURIComponent(String(requestParameters.positionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContractPositionToJSON(requestParameters.contractPosition),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdatePosition(requestParameters: CreateOrUpdatePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdatePositionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createPoolPositionRaw(requestParameters: CreatePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PoolContractPosition>> {
        if (requestParameters.poolContractPosition === null || requestParameters.poolContractPosition === undefined) {
            throw new runtime.RequiredError('poolContractPosition','Required parameter requestParameters.poolContractPosition was null or undefined when calling createPoolPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/pool`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoolContractPositionToJSON(requestParameters.poolContractPosition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoolContractPositionFromJSON(jsonValue));
    }

    /**
     */
    async createPoolPosition(requestParameters: CreatePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PoolContractPosition> {
        const response = await this.createPoolPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePoolPositionRaw(requestParameters: DeletePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling deletePoolPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/pool/{positionId}`.replace(`{${"positionId"}}`, encodeURIComponent(String(requestParameters.positionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePoolPosition(requestParameters: DeletePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePoolPositionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePositionRaw(requestParameters: DeletePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling deletePosition.');
        }

        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling deletePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/used/{contractId}/{positionId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))).replace(`{${"positionId"}}`, encodeURIComponent(String(requestParameters.positionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePosition(requestParameters: DeletePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePositionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getPoolPositionsRaw(requestParameters: GetPoolPositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PoolContractPosition>>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/pool`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoolContractPositionFromJSON));
    }

    /**
     */
    async getPoolPositions(requestParameters: GetPoolPositionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PoolContractPosition>> {
        const response = await this.getPoolPositionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPositionCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContractPositionCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positionCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractPositionCategoryFromJSON));
    }

    /**
     */
    async getPositionCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContractPositionCategory>> {
        const response = await this.getPositionCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUnitsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getUnits(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getUnitsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsedPositionsRaw(requestParameters: GetUsedPositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContractPosition>>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling getUsedPositions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/used/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractPositionFromJSON));
    }

    /**
     */
    async getUsedPositions(requestParameters: GetUsedPositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContractPosition>> {
        const response = await this.getUsedPositionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePoolPositionRaw(requestParameters: UpdatePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling updatePoolPosition.');
        }

        if (requestParameters.poolContractPosition === null || requestParameters.poolContractPosition === undefined) {
            throw new runtime.RequiredError('poolContractPosition','Required parameter requestParameters.poolContractPosition was null or undefined when calling updatePoolPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/positions/pool/{positionId}`.replace(`{${"positionId"}}`, encodeURIComponent(String(requestParameters.positionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoolContractPositionToJSON(requestParameters.poolContractPosition),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePoolPosition(requestParameters: UpdatePoolPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePoolPositionRaw(requestParameters, initOverrides);
    }

}
