/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SapNummerAenderung
 */
export interface SapNummerAenderung {
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    berichtsID: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    erstellt: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    tagesberichtVon: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    tagesberichtBis: string;
    /**
     * 
     * @type {number}
     * @memberof SapNummerAenderung
     */
    abschnittsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    sapNummerIst: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerAenderung
     */
    sapNummerSoll?: string;
}

/**
 * Check if a given object implements the SapNummerAenderung interface.
 */
export function instanceOfSapNummerAenderung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtsID" in value;
    isInstance = isInstance && "erstellt" in value;
    isInstance = isInstance && "tagesberichtVon" in value;
    isInstance = isInstance && "tagesberichtBis" in value;
    isInstance = isInstance && "abschnittsNummer" in value;
    isInstance = isInstance && "sapNummerIst" in value;

    return isInstance;
}

export function SapNummerAenderungFromJSON(json: any): SapNummerAenderung {
    return SapNummerAenderungFromJSONTyped(json, false);
}

export function SapNummerAenderungFromJSONTyped(json: any, ignoreDiscriminator: boolean): SapNummerAenderung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtsID': json['BerichtsID'],
        'erstellt': json['Erstellt'],
        'tagesberichtVon': json['TagesberichtVon'],
        'tagesberichtBis': json['TagesberichtBis'],
        'abschnittsNummer': json['AbschnittsNummer'],
        'sapNummerIst': json['SapNummerIst'],
        'sapNummerSoll': !exists(json, 'SapNummerSoll') ? undefined : json['SapNummerSoll'],
    };
}

export function SapNummerAenderungToJSON(value?: SapNummerAenderung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtsID': value.berichtsID,
        'Erstellt': value.erstellt,
        'TagesberichtVon': value.tagesberichtVon,
        'TagesberichtBis': value.tagesberichtBis,
        'AbschnittsNummer': value.abschnittsNummer,
        'SapNummerIst': value.sapNummerIst,
        'SapNummerSoll': value.sapNummerSoll,
    };
}

