/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractParameters
 */
export interface ContractParameters {
    /**
     * 
     * @type {number}
     * @memberof ContractParameters
     */
    dueDateDaysERH?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractParameters
     */
    dueDateDaysNL?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractParameters
     */
    reverseCharge?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractParameters
     */
    paymentCondition?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractParameters
     */
    contractPositionCategoryId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContractParameters
     */
    includeServiceRecipientInTemplate: boolean;
}

/**
 * Check if a given object implements the ContractParameters interface.
 */
export function instanceOfContractParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contractPositionCategoryId" in value;
    isInstance = isInstance && "includeServiceRecipientInTemplate" in value;

    return isInstance;
}

export function ContractParametersFromJSON(json: any): ContractParameters {
    return ContractParametersFromJSONTyped(json, false);
}

export function ContractParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDateDaysERH': !exists(json, 'dueDateDaysERH') ? undefined : json['dueDateDaysERH'],
        'dueDateDaysNL': !exists(json, 'dueDateDaysNL') ? undefined : json['dueDateDaysNL'],
        'reverseCharge': !exists(json, 'reverseCharge') ? undefined : json['reverseCharge'],
        'paymentCondition': !exists(json, 'paymentCondition') ? undefined : json['paymentCondition'],
        'contractPositionCategoryId': json['contractPositionCategoryId'],
        'includeServiceRecipientInTemplate': json['includeServiceRecipientInTemplate'],
    };
}

export function ContractParametersToJSON(value?: ContractParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDateDaysERH': value.dueDateDaysERH,
        'dueDateDaysNL': value.dueDateDaysNL,
        'reverseCharge': value.reverseCharge,
        'paymentCondition': value.paymentCondition,
        'contractPositionCategoryId': value.contractPositionCategoryId,
        'includeServiceRecipientInTemplate': value.includeServiceRecipientInTemplate,
    };
}

