/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgZusammengefalteteEinsatzzeit
 */
export interface DbbgZusammengefalteteEinsatzzeit {
    /**
     * 
     * @type {string}
     * @memberof DbbgZusammengefalteteEinsatzzeit
     */
    typ: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgZusammengefalteteEinsatzzeit
     */
    tarif: DbbgZusammengefalteteEinsatzzeitTarifEnum;
    /**
     * 
     * @type {string}
     * @memberof DbbgZusammengefalteteEinsatzzeit
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgZusammengefalteteEinsatzzeit
     */
    bis: string;
}


/**
 * @export
 */
export const DbbgZusammengefalteteEinsatzzeitTarifEnum = {
    Tag: 'Tag',
    Nacht: 'Nacht',
    Samstag: 'Samstag',
    Sonntag: 'Sonntag',
    Feiertag: 'Feiertag'
} as const;
export type DbbgZusammengefalteteEinsatzzeitTarifEnum = typeof DbbgZusammengefalteteEinsatzzeitTarifEnum[keyof typeof DbbgZusammengefalteteEinsatzzeitTarifEnum];


/**
 * Check if a given object implements the DbbgZusammengefalteteEinsatzzeit interface.
 */
export function instanceOfDbbgZusammengefalteteEinsatzzeit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "tarif" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;

    return isInstance;
}

export function DbbgZusammengefalteteEinsatzzeitFromJSON(json: any): DbbgZusammengefalteteEinsatzzeit {
    return DbbgZusammengefalteteEinsatzzeitFromJSONTyped(json, false);
}

export function DbbgZusammengefalteteEinsatzzeitFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgZusammengefalteteEinsatzzeit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typ': json['Typ'],
        'tarif': json['Tarif'],
        'von': json['Von'],
        'bis': json['Bis'],
    };
}

export function DbbgZusammengefalteteEinsatzzeitToJSON(value?: DbbgZusammengefalteteEinsatzzeit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Typ': value.typ,
        'Tarif': value.tarif,
        'Von': value.von,
        'Bis': value.bis,
    };
}

