/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AbnahmeSetzenRequest,
  BerichtMitRechnungsdaten,
  BerichtsUrlTyp,
  EintraegeErgebnis,
  Eintrag,
  EintragZuordnung,
  EintragsZuordnungen,
  RechnungsdatenSpeichernRequest,
  SapNummerAenderung,
  SapNummerStatus,
} from '../models/index';
import {
    AbnahmeSetzenRequestFromJSON,
    AbnahmeSetzenRequestToJSON,
    BerichtMitRechnungsdatenFromJSON,
    BerichtMitRechnungsdatenToJSON,
    BerichtsUrlTypFromJSON,
    BerichtsUrlTypToJSON,
    EintraegeErgebnisFromJSON,
    EintraegeErgebnisToJSON,
    EintragFromJSON,
    EintragToJSON,
    EintragZuordnungFromJSON,
    EintragZuordnungToJSON,
    EintragsZuordnungenFromJSON,
    EintragsZuordnungenToJSON,
    RechnungsdatenSpeichernRequestFromJSON,
    RechnungsdatenSpeichernRequestToJSON,
    SapNummerAenderungFromJSON,
    SapNummerAenderungToJSON,
    SapNummerStatusFromJSON,
    SapNummerStatusToJSON,
} from '../models/index';

export interface AbnahmeSetzenOperationRequest {
    id: string;
    abnahmeSetzenRequest: AbnahmeSetzenRequest;
}

export interface AbnahmeprotokollGedrucktSetzenRequest {
    id: string;
}

export interface AbschliessenRequest {
    id: string;
    abschlussGrund: string;
}

export interface AbschliessenRueckgaengigRequest {
    id: string;
}

export interface BerichtMitRechnungsdatenRequest {
    id: string;
}

export interface BerichtVonExternHochladenRequest {
    id: string;
    bericht: string;
}

export interface BerichteAnExternVersendetRequest {
    ids: Array<string>;
}

export interface BerichtslistenEintraegeRequest {
    seite: number;
    anzahlEintrge: number;
    filter?: string;
    mitEreignisListe?: boolean;
}

export interface BerichtslistenEintragRequest {
    id: string;
}

export interface DateiHerunterladenRequest {
    id: string;
    typ: BerichtsUrlTyp;
}

export interface DateiUrlAbholenRequest {
    id: string;
    typ: BerichtsUrlTyp;
}

export interface EintragsZuordnungenAbfragenRequest {
    id: string;
}

export interface EintragsZuordnungenSetzenRequest {
    id: string;
    eintragsZuordnungen: Array<string>;
}

export interface EmailVorlageHerunterladenRequest {
    ids: Array<string>;
}

export interface FuerPruefungSperrenRequest {
    id: string;
}

export interface PruefungAblehnenRequest {
    id: string;
    kommentar: string;
}

export interface PruefungAkzeptierenRequest {
    id: string;
}

export interface RechnungsVorlageHerunterladenRequest {
    id: string;
}

export interface RechnungsdatenSpeichernOperationRequest {
    id: string;
    rechnungsdatenSpeichernRequest?: RechnungsdatenSpeichernRequest;
}

export interface SapNummernAenderungBeauftragenRequest {
    id: string;
    sapNummerAenderung: Array<SapNummerAenderung>;
}

export interface SapNummernStatusRequest {
    id: string;
}

export interface VerrechenbarSetzenRequest {
    id: string;
}

export interface VerrechnetSetzenRequest {
    id: string;
}

export interface ZuordenbareEintraegeRequest {
    id: string;
    toleranzStundenBeginn?: number;
    toleranzStundenEnde?: number;
}

export interface ZuruecksetzenRequest {
    id: string;
    grund?: string;
}

/**
 * 
 */
export class JobsApi extends runtime.BaseAPI {

    /**
     * Speichert eine Abnahme mit Abnahmeprotokoll und setzt den Status auf verrechenbar oder nicht verrechenbar.
     */
    async abnahmeSetzenRaw(requestParameters: AbnahmeSetzenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling abnahmeSetzen.');
        }

        if (requestParameters.abnahmeSetzenRequest === null || requestParameters.abnahmeSetzenRequest === undefined) {
            throw new runtime.RequiredError('abnahmeSetzenRequest','Required parameter requestParameters.abnahmeSetzenRequest was null or undefined when calling abnahmeSetzen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/approval`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AbnahmeSetzenRequestToJSON(requestParameters.abnahmeSetzenRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert eine Abnahme mit Abnahmeprotokoll und setzt den Status auf verrechenbar oder nicht verrechenbar.
     */
    async abnahmeSetzen(requestParameters: AbnahmeSetzenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.abnahmeSetzenRaw(requestParameters, initOverrides);
    }

    /**
     * Setzt ein Benutzerereignis \"Abnahmeprotokoll gedruckt\".
     */
    async abnahmeprotokollGedrucktSetzenRaw(requestParameters: AbnahmeprotokollGedrucktSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling abnahmeprotokollGedrucktSetzen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/approvalPrinted`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Setzt ein Benutzerereignis \"Abnahmeprotokoll gedruckt\".
     */
    async abnahmeprotokollGedrucktSetzen(requestParameters: AbnahmeprotokollGedrucktSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.abnahmeprotokollGedrucktSetzenRaw(requestParameters, initOverrides);
    }

    /**
     * Schließt einen (möglicherweise unvollständigen) Eintrag ab.
     * Schließt einen Eintrag ab.
     */
    async abschliessenRaw(requestParameters: AbschliessenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling abschliessen.');
        }

        if (requestParameters.abschlussGrund === null || requestParameters.abschlussGrund === undefined) {
            throw new runtime.RequiredError('abschlussGrund','Required parameter requestParameters.abschlussGrund was null or undefined when calling abschliessen.');
        }

        const queryParameters: any = {};

        if (requestParameters.abschlussGrund !== undefined) {
            queryParameters['AbschlussGrund'] = requestParameters.abschlussGrund;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/finalize`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Schließt einen (möglicherweise unvollständigen) Eintrag ab.
     * Schließt einen Eintrag ab.
     */
    async abschliessen(requestParameters: AbschliessenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.abschliessenRaw(requestParameters, initOverrides);
    }

    /**
     * Macht die Schließung rückgängig.
     * Macht die Schließung rückgängig.
     */
    async abschliessenRueckgaengigRaw(requestParameters: AbschliessenRueckgaengigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling abschliessenRueckgaengig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/revertReset`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Macht die Schließung rückgängig.
     * Macht die Schließung rückgängig.
     */
    async abschliessenRueckgaengig(requestParameters: AbschliessenRueckgaengigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.abschliessenRueckgaengigRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt einen Bericht mit Rechnungsdaten zurück
     */
    async berichtMitRechnungsdatenRaw(requestParameters: BerichtMitRechnungsdatenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerichtMitRechnungsdaten>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berichtMitRechnungsdaten.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerichtMitRechnungsdatenFromJSON(jsonValue));
    }

    /**
     * Gibt einen Bericht mit Rechnungsdaten zurück
     */
    async berichtMitRechnungsdaten(requestParameters: BerichtMitRechnungsdatenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerichtMitRechnungsdaten> {
        const response = await this.berichtMitRechnungsdatenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bericht von externem Kunden hochladen.
     */
    async berichtVonExternHochladenRaw(requestParameters: BerichtVonExternHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berichtVonExternHochladen.');
        }

        if (requestParameters.bericht === null || requestParameters.bericht === undefined) {
            throw new runtime.RequiredError('bericht','Required parameter requestParameters.bericht was null or undefined when calling berichtVonExternHochladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bericht !== undefined) {
            formParams.append('Bericht', requestParameters.bericht as any);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/externalReceive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bericht von externem Kunden hochladen.
     */
    async berichtVonExternHochladen(requestParameters: BerichtVonExternHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.berichtVonExternHochladenRaw(requestParameters, initOverrides);
    }

    /**
     * Status für Berichte als an externen Kunden versendet setzen.
     */
    async berichteAnExternVersendetRaw(requestParameters: BerichteAnExternVersendetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling berichteAnExternVersendet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/mail/externalSent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ids,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Status für Berichte als an externen Kunden versendet setzen.
     */
    async berichteAnExternVersendet(requestParameters: BerichteAnExternVersendetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.berichteAnExternVersendetRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt eine Seite von Einträgen der Berichtsliste zurück. Die Seitennummer (1 für erste Seite) und Anzahl der Einträge wird über die Parameter definiert.
     * Gibt Einträge der Berichtsliste zurück.
     */
    async berichtslistenEintraegeRaw(requestParameters: BerichtslistenEintraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EintraegeErgebnis>> {
        if (requestParameters.seite === null || requestParameters.seite === undefined) {
            throw new runtime.RequiredError('seite','Required parameter requestParameters.seite was null or undefined when calling berichtslistenEintraege.');
        }

        if (requestParameters.anzahlEintrge === null || requestParameters.anzahlEintrge === undefined) {
            throw new runtime.RequiredError('anzahlEintrge','Required parameter requestParameters.anzahlEintrge was null or undefined when calling berichtslistenEintraege.');
        }

        const queryParameters: any = {};

        if (requestParameters.seite !== undefined) {
            queryParameters['Seite'] = requestParameters.seite;
        }

        if (requestParameters.anzahlEintrge !== undefined) {
            queryParameters['AnzahlEinträge'] = requestParameters.anzahlEintrge;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.mitEreignisListe !== undefined) {
            queryParameters['MitEreignisListe'] = requestParameters.mitEreignisListe;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EintraegeErgebnisFromJSON(jsonValue));
    }

    /**
     * Gibt eine Seite von Einträgen der Berichtsliste zurück. Die Seitennummer (1 für erste Seite) und Anzahl der Einträge wird über die Parameter definiert.
     * Gibt Einträge der Berichtsliste zurück.
     */
    async berichtslistenEintraege(requestParameters: BerichtslistenEintraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EintraegeErgebnis> {
        const response = await this.berichtslistenEintraegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt den Eintrag der Berichtliste für eine angegebene ID zurück.
     * Gibt einen Eintrag der Berichtsliste zurück.
     */
    async berichtslistenEintragRaw(requestParameters: BerichtslistenEintragRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Eintrag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berichtslistenEintrag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EintragFromJSON(jsonValue));
    }

    /**
     * Gibt den Eintrag der Berichtliste für eine angegebene ID zurück.
     * Gibt einen Eintrag der Berichtsliste zurück.
     */
    async berichtslistenEintrag(requestParameters: BerichtslistenEintragRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Eintrag> {
        const response = await this.berichtslistenEintragRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lädt eine Datei eines Berichtes herunter. Die Datei liegt als Data-URI (z.B. \'data:image/png;base64,iVBORw0KGgoA...\') vor.
     * Lädt eine Datei eines Berichtes herunter.
     */
    async dateiHerunterladenRaw(requestParameters: DateiHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dateiHerunterladen.');
        }

        if (requestParameters.typ === null || requestParameters.typ === undefined) {
            throw new runtime.RequiredError('typ','Required parameter requestParameters.typ was null or undefined when calling dateiHerunterladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/files/{Typ}/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"Typ"}}`, encodeURIComponent(String(requestParameters.typ))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt eine Datei eines Berichtes herunter. Die Datei liegt als Data-URI (z.B. \'data:image/png;base64,iVBORw0KGgoA...\') vor.
     * Lädt eine Datei eines Berichtes herunter.
     */
    async dateiHerunterladen(requestParameters: DateiHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dateiHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Holt eine Download-URL für eine Datei eines Berichtes ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt eine Download-URL für eine Datei eines Berichtes ab.
     */
    async dateiUrlAbholenRaw(requestParameters: DateiUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dateiUrlAbholen.');
        }

        if (requestParameters.typ === null || requestParameters.typ === undefined) {
            throw new runtime.RequiredError('typ','Required parameter requestParameters.typ was null or undefined when calling dateiUrlAbholen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/files/{Typ}/url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"Typ"}}`, encodeURIComponent(String(requestParameters.typ))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Holt eine Download-URL für eine Datei eines Berichtes ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt eine Download-URL für eine Datei eines Berichtes ab.
     */
    async dateiUrlAbholen(requestParameters: DateiUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dateiUrlAbholenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt die einem Eintrag zugeordneten Einträge zurück.
     */
    async eintragsZuordnungenAbfragenRaw(requestParameters: EintragsZuordnungenAbfragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EintragsZuordnungen>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling eintragsZuordnungenAbfragen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/assignments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EintragsZuordnungenFromJSON(jsonValue));
    }

    /**
     * Gibt die einem Eintrag zugeordneten Einträge zurück.
     */
    async eintragsZuordnungenAbfragen(requestParameters: EintragsZuordnungenAbfragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EintragsZuordnungen> {
        const response = await this.eintragsZuordnungenAbfragenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ordnet einem Eintrag andere Einträge zu (Einsätze zu Bericht, oder umgekehrt).
     */
    async eintragsZuordnungenSetzenRaw(requestParameters: EintragsZuordnungenSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling eintragsZuordnungenSetzen.');
        }

        if (requestParameters.eintragsZuordnungen === null || requestParameters.eintragsZuordnungen === undefined) {
            throw new runtime.RequiredError('eintragsZuordnungen','Required parameter requestParameters.eintragsZuordnungen was null or undefined when calling eintragsZuordnungenSetzen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/assignments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.eintragsZuordnungen,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ordnet einem Eintrag andere Einträge zu (Einsätze zu Bericht, oder umgekehrt).
     */
    async eintragsZuordnungenSetzen(requestParameters: EintragsZuordnungenSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.eintragsZuordnungenSetzenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt einen E-Mail Entwurf der die Protokolle zur externen Freigabe beinhaltet zurück.
     * E-Mail Entwurf mit den Protokollen im Anhang herunterladen.
     */
    async emailVorlageHerunterladenRaw(requestParameters: EmailVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling emailVorlageHerunterladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/mail/downloadDraft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ids,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gibt einen E-Mail Entwurf der die Protokolle zur externen Freigabe beinhaltet zurück.
     * E-Mail Entwurf mit den Protokollen im Anhang herunterladen.
     */
    async emailVorlageHerunterladen(requestParameters: EmailVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.emailVorlageHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sperrt einen Bericht für die Prüfung, um gleichzeitige Veränderungen vom Einreicher zu verhindern.
     * Sperrt einen Bericht für die Prüfung.
     */
    async fuerPruefungSperrenRaw(requestParameters: FuerPruefungSperrenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fuerPruefungSperren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/review/lock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sperrt einen Bericht für die Prüfung, um gleichzeitige Veränderungen vom Einreicher zu verhindern.
     * Sperrt einen Bericht für die Prüfung.
     */
    async fuerPruefungSperren(requestParameters: FuerPruefungSperrenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fuerPruefungSperrenRaw(requestParameters, initOverrides);
    }

    /**
     * Schließt die Prüfung des Berichtes als fehlerbehaftet ab und gibt ihn zurück zum Einreicher, damit dieser die Fehler beheben kann.
     * Lehnt den Bericht aufgrund von Fehlern ab.
     */
    async pruefungAblehnenRaw(requestParameters: PruefungAblehnenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pruefungAblehnen.');
        }

        if (requestParameters.kommentar === null || requestParameters.kommentar === undefined) {
            throw new runtime.RequiredError('kommentar','Required parameter requestParameters.kommentar was null or undefined when calling pruefungAblehnen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.kommentar !== undefined) {
            formParams.append('Kommentar', requestParameters.kommentar as any);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/review/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Schließt die Prüfung des Berichtes als fehlerbehaftet ab und gibt ihn zurück zum Einreicher, damit dieser die Fehler beheben kann.
     * Lehnt den Bericht aufgrund von Fehlern ab.
     */
    async pruefungAblehnen(requestParameters: PruefungAblehnenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pruefungAblehnenRaw(requestParameters, initOverrides);
    }

    /**
     * Schließt die Prüfung der Berichtes als fehlerfrei ab und erlaubt somit weritere Bearbeitung.
     * Akzeptiert den Bericht als Fehlerfrei.
     */
    async pruefungAkzeptierenRaw(requestParameters: PruefungAkzeptierenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pruefungAkzeptieren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/review/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Schließt die Prüfung der Berichtes als fehlerfrei ab und erlaubt somit weritere Bearbeitung.
     * Akzeptiert den Bericht als Fehlerfrei.
     */
    async pruefungAkzeptieren(requestParameters: PruefungAkzeptierenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pruefungAkzeptierenRaw(requestParameters, initOverrides);
    }

    /**
     * Lädt die mit Rechnungsdaten vorbefüllte Rechnungsvorlage herunter.
     */
    async rechnungsVorlageHerunterladenRaw(requestParameters: RechnungsVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rechnungsVorlageHerunterladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/invoiceTemplate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt die mit Rechnungsdaten vorbefüllte Rechnungsvorlage herunter.
     */
    async rechnungsVorlageHerunterladen(requestParameters: RechnungsVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.rechnungsVorlageHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rechnungsdaten zu einem Eintrag hinzufügen.
     */
    async rechnungsdatenSpeichernRaw(requestParameters: RechnungsdatenSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rechnungsdatenSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RechnungsdatenSpeichernRequestToJSON(requestParameters.rechnungsdatenSpeichernRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rechnungsdaten zu einem Eintrag hinzufügen.
     */
    async rechnungsdatenSpeichern(requestParameters: RechnungsdatenSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rechnungsdatenSpeichernRaw(requestParameters, initOverrides);
    }

    /**
     * Speichert den Soll-Zustand für SAP-Nummern eines Berichts.
     */
    async sapNummernAenderungBeauftragenRaw(requestParameters: SapNummernAenderungBeauftragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sapNummernAenderungBeauftragen.');
        }

        if (requestParameters.sapNummerAenderung === null || requestParameters.sapNummerAenderung === undefined) {
            throw new runtime.RequiredError('sapNummerAenderung','Required parameter requestParameters.sapNummerAenderung was null or undefined when calling sapNummernAenderungBeauftragen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/sapNumber`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.sapNummerAenderung.map(SapNummerAenderungToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert den Soll-Zustand für SAP-Nummern eines Berichts.
     */
    async sapNummernAenderungBeauftragen(requestParameters: SapNummernAenderungBeauftragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sapNummernAenderungBeauftragenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt für einen Bericht eine Liste mit Ist- und Soll-Zustand einer SAP-Nummer zurück.
     */
    async sapNummernStatusRaw(requestParameters: SapNummernStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SapNummerStatus>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sapNummernStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/sapNumber`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SapNummerStatusFromJSON));
    }

    /**
     * Gibt für einen Bericht eine Liste mit Ist- und Soll-Zustand einer SAP-Nummer zurück.
     */
    async sapNummernStatus(requestParameters: SapNummernStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SapNummerStatus>> {
        const response = await this.sapNummernStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setzt Status verrechenbar für einen zuvor abgenommenen aber noch nicht verrechenbaren (Nacharbeit) Bericht.
     */
    async verrechenbarSetzenRaw(requestParameters: VerrechenbarSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verrechenbarSetzen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/billable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Setzt Status verrechenbar für einen zuvor abgenommenen aber noch nicht verrechenbaren (Nacharbeit) Bericht.
     */
    async verrechenbarSetzen(requestParameters: VerrechenbarSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verrechenbarSetzenRaw(requestParameters, initOverrides);
    }

    /**
     * Der Bericht wird als verrechnet gespeichert und damit abgeschlossen.
     */
    async verrechnetSetzenRaw(requestParameters: VerrechnetSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verrechnetSetzen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Der Bericht wird als verrechnet gespeichert und damit abgeschlossen.
     */
    async verrechnetSetzen(requestParameters: VerrechnetSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verrechnetSetzenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt Einträge der Berichtsliste zurück, die dem übergebenen Eintrag zugeordnet werden können.
     * Gibt zuordnenbare Einträge zurück.
     */
    async zuordenbareEintraegeRaw(requestParameters: ZuordenbareEintraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EintragZuordnung>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling zuordenbareEintraege.');
        }

        const queryParameters: any = {};

        if (requestParameters.toleranzStundenBeginn !== undefined) {
            queryParameters['ToleranzStundenBeginn'] = requestParameters.toleranzStundenBeginn;
        }

        if (requestParameters.toleranzStundenEnde !== undefined) {
            queryParameters['ToleranzStundenEnde'] = requestParameters.toleranzStundenEnde;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/assignmentCandidates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EintragZuordnungFromJSON));
    }

    /**
     * Gibt Einträge der Berichtsliste zurück, die dem übergebenen Eintrag zugeordnet werden können.
     * Gibt zuordnenbare Einträge zurück.
     */
    async zuordenbareEintraege(requestParameters: ZuordenbareEintraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EintragZuordnung>> {
        const response = await this.zuordenbareEintraegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setzt den Fortschritt des Berichts im Prozess auf den Anfang zurück. Der Bericht ist dadurch nicht mehr eingericht.
     * Setzt den Prozess-Fortschritt des Berichts zurück.
     */
    async zuruecksetzenRaw(requestParameters: ZuruecksetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling zuruecksetzen.');
        }

        const queryParameters: any = {};

        if (requestParameters.grund !== undefined) {
            queryParameters['Grund'] = requestParameters.grund;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/{id}/reset`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Setzt den Fortschritt des Berichts im Prozess auf den Anfang zurück. Der Bericht ist dadurch nicht mehr eingericht.
     * Setzt den Prozess-Fortschritt des Berichts zurück.
     */
    async zuruecksetzen(requestParameters: ZuruecksetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.zuruecksetzenRaw(requestParameters, initOverrides);
    }

}
