/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BerichtsTyp = {
    Oebb: 'OEBB',
    Aorv: 'AORV',
    Dbbg: 'DBBG'
} as const;
export type BerichtsTyp = typeof BerichtsTyp[keyof typeof BerichtsTyp];


export function BerichtsTypFromJSON(json: any): BerichtsTyp {
    return BerichtsTypFromJSONTyped(json, false);
}

export function BerichtsTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtsTyp {
    return json as BerichtsTyp;
}

export function BerichtsTypToJSON(value?: BerichtsTyp | null): any {
    return value as any;
}

