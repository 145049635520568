/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BenutzerEreignis } from './BenutzerEreignis';
import {
    BenutzerEreignisFromJSON,
    BenutzerEreignisFromJSONTyped,
    BenutzerEreignisToJSON,
} from './BenutzerEreignis';

/**
 * 
 * @export
 * @interface DbbgRailiumEinsatz
 */
export interface DbbgRailiumEinsatz {
    /**
     * 
     * @type {number}
     * @memberof DbbgRailiumEinsatz
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgRailiumEinsatz
     */
    einsatzGruppenId?: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    einsatzart: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    status: DbbgRailiumEinsatzStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    bis: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    angebotsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    bestellnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRailiumEinsatz
     */
    kunde?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbbgRailiumEinsatz
     */
    eingereicht: boolean;
    /**
     * 
     * @type {BenutzerEreignis}
     * @memberof DbbgRailiumEinsatz
     */
    eingereichtEreignis?: BenutzerEreignis;
    /**
     * 
     * @type {number}
     * @memberof DbbgRailiumEinsatz
     */
    berichtsnummer?: number;
    /**
     * Wahr, wenn der Railium-Einsatz eingereicht sein muss, damit die ganze Gruppe eingereicht werden kann.
     * @type {boolean}
     * @memberof DbbgRailiumEinsatz
     */
    istRelevant: boolean;
}


/**
 * @export
 */
export const DbbgRailiumEinsatzStatusEnum = {
    Terminblocker: 'Terminblocker',
    Bestellt: 'Bestellt',
    Storniert: 'Storniert',
    Durchgefhrt: 'Durchgeführt',
    Abgenommen: 'Abgenommen',
    ZurVerrechnungFreigegeben: 'Zur Verrechnung freigegeben',
    Verrechnet: 'Verrechnet',
    Angeboten: 'Angeboten',
    OmAbgeschlossen: 'OM abgeschlossen',
    KurzfristigAbgesagt: 'Kurzfristig abgesagt',
    AusfallMaschinengebrechen: 'Ausfall Maschinengebrechen'
} as const;
export type DbbgRailiumEinsatzStatusEnum = typeof DbbgRailiumEinsatzStatusEnum[keyof typeof DbbgRailiumEinsatzStatusEnum];


/**
 * Check if a given object implements the DbbgRailiumEinsatz interface.
 */
export function instanceOfDbbgRailiumEinsatz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "einsatzart" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "eingereicht" in value;
    isInstance = isInstance && "istRelevant" in value;

    return isInstance;
}

export function DbbgRailiumEinsatzFromJSON(json: any): DbbgRailiumEinsatz {
    return DbbgRailiumEinsatzFromJSONTyped(json, false);
}

export function DbbgRailiumEinsatzFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgRailiumEinsatz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'einsatzGruppenId': !exists(json, 'EinsatzGruppenId') ? undefined : json['EinsatzGruppenId'],
        'name': json['Name'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'einsatzart': json['Einsatzart'],
        'status': json['Status'],
        'von': json['Von'],
        'bis': json['Bis'],
        'region': !exists(json, 'Region') ? undefined : json['Region'],
        'angebotsnummer': !exists(json, 'Angebotsnummer') ? undefined : json['Angebotsnummer'],
        'bestellnummer': !exists(json, 'Bestellnummer') ? undefined : json['Bestellnummer'],
        'kunde': !exists(json, 'Kunde') ? undefined : json['Kunde'],
        'eingereicht': json['Eingereicht'],
        'eingereichtEreignis': !exists(json, 'EingereichtEreignis') ? undefined : BenutzerEreignisFromJSON(json['EingereichtEreignis']),
        'berichtsnummer': !exists(json, 'Berichtsnummer') ? undefined : json['Berichtsnummer'],
        'istRelevant': json['IstRelevant'],
    };
}

export function DbbgRailiumEinsatzToJSON(value?: DbbgRailiumEinsatz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'EinsatzGruppenId': value.einsatzGruppenId,
        'Name': value.name,
        'MaschinenGruppe': value.maschinenGruppe,
        'Einsatzart': value.einsatzart,
        'Status': value.status,
        'Von': value.von,
        'Bis': value.bis,
        'Region': value.region,
        'Angebotsnummer': value.angebotsnummer,
        'Bestellnummer': value.bestellnummer,
        'Kunde': value.kunde,
        'Eingereicht': value.eingereicht,
        'EingereichtEreignis': BenutzerEreignisToJSON(value.eingereichtEreignis),
        'Berichtsnummer': value.berichtsnummer,
        'IstRelevant': value.istRelevant,
    };
}

