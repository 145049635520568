/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface MonatsSicherungRequest {
    berichtsIds: Array<string>;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Generiert eine \"Monats-Sicherung\" aus der aktuellsten Sicherung einer Maschinengruppe und gibt diese als E-Mail Vorlage (.eml) zurück. Die Anhänge in der E-Mail entsprechen dem \"Alles exportieren\" RVC. Das sind die Sicherung.zip, die extrahierte Auftrag1.xml mit anderem Namen und im CSV-Format die Leistungen und Stunden der angegebenen Berichte.
     * Gibt eine E-Mail Vorlage einer \"Monats-Sicherung\" zurück.
     */
    async monatsSicherungRaw(requestParameters: MonatsSicherungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.berichtsIds === null || requestParameters.berichtsIds === undefined) {
            throw new runtime.RequiredError('berichtsIds','Required parameter requestParameters.berichtsIds was null or undefined when calling monatsSicherung.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/jobs/monthlyZip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.berichtsIds,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generiert eine \"Monats-Sicherung\" aus der aktuellsten Sicherung einer Maschinengruppe und gibt diese als E-Mail Vorlage (.eml) zurück. Die Anhänge in der E-Mail entsprechen dem \"Alles exportieren\" RVC. Das sind die Sicherung.zip, die extrahierte Auftrag1.xml mit anderem Namen und im CSV-Format die Leistungen und Stunden der angegebenen Berichte.
     * Gibt eine E-Mail Vorlage einer \"Monats-Sicherung\" zurück.
     */
    async monatsSicherung(requestParameters: MonatsSicherungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.monatsSicherungRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
