/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BerichtAORV
 */
export interface BerichtAORV {
    /**
     * 
     * @type {string}
     * @memberof BerichtAORV
     */
    externeBestellNummer: string;
    /**
     * 
     * @type {string}
     * @memberof BerichtAORV
     */
    einsatzNummer: string;
    /**
     * 
     * @type {boolean}
     * @memberof BerichtAORV
     */
    beimEinreichenUnterschrieben: boolean;
}

/**
 * Check if a given object implements the BerichtAORV interface.
 */
export function instanceOfBerichtAORV(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externeBestellNummer" in value;
    isInstance = isInstance && "einsatzNummer" in value;
    isInstance = isInstance && "beimEinreichenUnterschrieben" in value;

    return isInstance;
}

export function BerichtAORVFromJSON(json: any): BerichtAORV {
    return BerichtAORVFromJSONTyped(json, false);
}

export function BerichtAORVFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtAORV {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externeBestellNummer': json['ExterneBestellNummer'],
        'einsatzNummer': json['EinsatzNummer'],
        'beimEinreichenUnterschrieben': json['BeimEinreichenUnterschrieben'],
    };
}

export function BerichtAORVToJSON(value?: BerichtAORV | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ExterneBestellNummer': value.externeBestellNummer,
        'EinsatzNummer': value.einsatzNummer,
        'BeimEinreichenUnterschrieben': value.beimEinreichenUnterschrieben,
    };
}

