<style scoped lang="scss"></style>

<template>
  <debug-menu-internal
    v-if="showDebugMenu"
    v-model:show-debug-menu="showDebugMenu"
  />
</template>

<script setup lang="ts">
  import { defineAsyncComponent, onMounted, onUnmounted, ref } from "vue";

  const DebugMenuInternal = defineAsyncComponent(
    () => import("./DebugMenuInternal.vue"),
  );

  const codeword = "debugmenu";

  const showDebugMenu = ref(false);

  let lastKeyPressTimestamp = 0;
  let progress = 0;

  const listener = (event: KeyboardEvent) => {
    const maxDiff = 1000;
    const currentTime = Date.now();
    if (currentTime - lastKeyPressTimestamp <= maxDiff) {
      onKeypress(event.key);
    } else {
      progress = 0;
      if (event.key === codeword[0]) {
        onKeypress(event.key);
      }
    }

    lastKeyPressTimestamp = currentTime;
  };

  function onKeypress(key: string) {
    if (codeword[progress] === key) {
      progress++;
      if (progress === codeword.length) {
        showDebugMenu.value = true;
      }
    } else {
      progress = 0;
    }
  }

  onMounted(() => {
    window.addEventListener("keydown", listener);
  });

  onUnmounted(() => {
    window.removeEventListener("keydown", listener);
  });
</script>