<template>
  <nav ref="navbar">
    <ul>
      <li class="item-home">
        <router-link to="/">
          <img
            class="rsrg-logo"
            src="/images/logo_rsrg_inverted.svg"
            alt="Logo Rhomberg Sersa Rail Group"
          />
        </router-link>
      </li>

      <li class="title-container">
        <h1 v-if="route.meta.headline">{{ route.meta.headline }}</h1>
      </li>

      <li class="item-login">
        <SignInButton v-if="!isAuthenticated" />

        <el-popover v-else placement="bottom-end" trigger="click" width="260">
          <template #reference>
            <el-avatar
              v-if="userPhoto"
              class="profile-picture"
              shape="square"
              :size="55"
              :src="userPhoto"
            />
            <div v-else class="empty-profile">
              <svg-icon type="mdi" :path="mdiAccount" :size="35" />
            </div>
          </template>

          <div class="profile-container">
            <div class="user-info">
              <div>
                <span style="font-size: 1.3em; font-weight: bold">{{
                  userDetails?.displayName
                }}</span
                ><br />
                <small
                  >{{ userDetails?.jobTitle }}<br />{{
                    userDetails?.companyName
                  }}</small
                >
              </div>
              <div v-if="userPhoto">
                <el-avatar shape="circle" :size="55" :src="userPhoto" />
              </div>
            </div>

            <div class="logout-button">
              <el-button @click="logoutRedirect()">
                <svg-icon
                  type="mdi"
                  :path="mdiLogout"
                  style="margin-right: 0.5em"
                />
                Abmelden
              </el-button>
            </div>
          </div>
        </el-popover>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
  import SvgIcon from "@jamescoyle/vue-icon";
  import { mdiAccount, mdiLogout } from "@mdi/js";
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import SignInButton from "./SignInButton.vue";
  import {
    computed,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    watch,
    WatchStopHandle,
  } from "vue";
  import { showProductionSystemWarning } from "@/utils/devutils";
  import { useRoute } from "vue-router";
  import { useUserProfile } from "@/composition-api/useUserProfile";
  import { ElAvatar, ElPopover } from "element-plus";
  import { useMsal } from "@/composition-api/useMsal";
  import UserInfo from "@/utils/UserInfo";

  const currentInstance = getCurrentInstance();
  const isAuthenticated = useIsAuthenticated();

  const navbar = ref<HTMLElement>();
  const route = useRoute();

  const currentUserId = ref<string>();
  const getUserInfo = ref<(id: string) => Ref<UserInfo | undefined>>();
  const getUserPhoto = ref<(id: string) => Ref<string | undefined>>();

  const userDetails = computed(() => {
    if (currentUserId.value) {
      const v = getUserInfo.value!(currentUserId.value).value;
      return v;
    } else {
      return undefined;
    }
  });
  const userPhoto = computed(() => {
    if (currentUserId.value) {
      const aaaa = getUserPhoto.value!(currentUserId.value);
      const v = aaaa.value;
      return v;
    } else {
      return undefined;
    }
  });

  watch(
    [showProductionSystemWarning, navbar],
    (v) => {
      if (showProductionSystemWarning.value && navbar.value) {
        navbar.value.style.backgroundColor = "rgb(255 105 72)";
      }
    },
    { immediate: true },
  );

  var unwatchAuth: WatchStopHandle;
  onMounted(() => {
    unwatchAuth = watch(
      isAuthenticated,
      () => {
        if (isAuthenticated.value) {
          const up = useUserProfile(currentInstance);
          getUserInfo.value = up.getUserInfo;
          getUserPhoto.value = up.getUserPhoto;
          watch(up.currentUserId, (v) => (currentUserId.value = v), {
            immediate: true,
          });
        }
      },
      { immediate: true },
    );
  });

  onUnmounted(() => {
    unwatchAuth?.();
  });

  const { instance } = useMsal();

  const logoutRedirect = () => {
    instance.logoutRedirect();
  };
</script>
<style scoped lang="scss">
  #authButton {
    margin-left: auto;
  }

  nav {
    color: white;
    height: 5.5em;
    width: 100%;
    box-sizing: border-box;
    background-color: #3d3d3db6;
    margin-bottom: 0.5em;
    padding: 0 1em;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    > ul {
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      column-gap: 1em;

      > li {
        display: inline-flex;
        align-items: center;

        &.item-home {
          @keyframes ❤️ {
            0% {
              visibility: hidden;
            }
            99% {
              visibility: hidden;
            }
            100% {
              visibility: visible;
            }
          }

          &::after {
            display: none;
            visibility: hidden;
            position: absolute;
            top: 5em;
            content: "Made with ❤️ for you! And 😡 for Microsoft.";
            padding: 1em;
            background-color: #d1dbe5;
            border: solid 1px #000;
            border-radius: 1em;
          }

          &:hover::after {
            display: block;
            animation: ❤️ 20s;
            visibility: visible;
          }
        }
      }
    }
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.3em;

    .logout-button {
      text-align: right;
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    column-gap: 0.5em;

    div:first-child {
      flex-grow: 1;
    }
  }

  .profile-picture {
    height: 4.5em;
    width: 4.5em;
    border-radius: 20%;
    cursor: pointer;
  }

  .empty-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c0c4cc;
    height: 3.5em;
    width: 3.5em;
    border-radius: 5%;
    border: solid #ff6300 2px;
    color: #ff6300;
    cursor: pointer;
  }

  .rsrg-logo {
    width: 230px;
    height: 83px;
  }

  .title-container {
    display: flex;
    justify-content: start;
    width: 100%;
  }

  @media only screen and (max-width: 761px) {
    .rsrg-logo {
      width: 130px;
      height: 46px;
    }

    h1 {
      font-size: 1em;
    }
  }
</style>
