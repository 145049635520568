/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';

/**
 * 
 * @export
 * @interface BerichtsStatusElement
 */
export interface BerichtsStatusElement {
    /**
     * 
     * @type {string}
     * @memberof BerichtsStatusElement
     */
    bezeichnung: string;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof BerichtsStatusElement
     */
    wert: BerichtsStatus;
}

/**
 * Check if a given object implements the BerichtsStatusElement interface.
 */
export function instanceOfBerichtsStatusElement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bezeichnung" in value;
    isInstance = isInstance && "wert" in value;

    return isInstance;
}

export function BerichtsStatusElementFromJSON(json: any): BerichtsStatusElement {
    return BerichtsStatusElementFromJSONTyped(json, false);
}

export function BerichtsStatusElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtsStatusElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bezeichnung': json['Bezeichnung'],
        'wert': BerichtsStatusFromJSON(json['Wert']),
    };
}

export function BerichtsStatusElementToJSON(value?: BerichtsStatusElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Bezeichnung': value.bezeichnung,
        'Wert': BerichtsStatusToJSON(value.wert),
    };
}

