/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ZuordnungTyp = {
    Manuell: 'MANUELL',
    Automatisch: 'AUTOMATISCH',
    Verwaltet: 'VERWALTET'
} as const;
export type ZuordnungTyp = typeof ZuordnungTyp[keyof typeof ZuordnungTyp];


export function ZuordnungTypFromJSON(json: any): ZuordnungTyp {
    return ZuordnungTypFromJSONTyped(json, false);
}

export function ZuordnungTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZuordnungTyp {
    return json as ZuordnungTyp;
}

export function ZuordnungTypToJSON(value?: ZuordnungTyp | null): any {
    return value as any;
}

