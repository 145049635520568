/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RechnungsdatenOebbArge
 */
export interface RechnungsdatenOebbArge {
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenOebbArge
     */
    abrufbestellnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenOebbArge
     */
    rechnungsnummer?: string;
}

/**
 * Check if a given object implements the RechnungsdatenOebbArge interface.
 */
export function instanceOfRechnungsdatenOebbArge(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechnungsdatenOebbArgeFromJSON(json: any): RechnungsdatenOebbArge {
    return RechnungsdatenOebbArgeFromJSONTyped(json, false);
}

export function RechnungsdatenOebbArgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechnungsdatenOebbArge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abrufbestellnummer': !exists(json, 'Abrufbestellnummer') ? undefined : json['Abrufbestellnummer'],
        'rechnungsnummer': !exists(json, 'Rechnungsnummer') ? undefined : json['Rechnungsnummer'],
    };
}

export function RechnungsdatenOebbArgeToJSON(value?: RechnungsdatenOebbArge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Abrufbestellnummer': value.abrufbestellnummer,
        'Rechnungsnummer': value.rechnungsnummer,
    };
}

