/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BenutzerEreignis } from './BenutzerEreignis';
import {
    BenutzerEreignisFromJSON,
    BenutzerEreignisFromJSONTyped,
    BenutzerEreignisToJSON,
} from './BenutzerEreignis';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';
import type { JsonReportError } from './JsonReportError';
import {
    JsonReportErrorFromJSON,
    JsonReportErrorFromJSONTyped,
    JsonReportErrorToJSON,
} from './JsonReportError';

/**
 * 
 * @export
 * @interface JsonReportCheckWithState
 */
export interface JsonReportCheckWithState {
    /**
     * 
     * @type {number}
     * @memberof JsonReportCheckWithState
     */
    berichtsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof JsonReportCheckWithState
     */
    berichtsID?: string;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof JsonReportCheckWithState
     */
    status?: BerichtsStatus;
    /**
     * 
     * @type {string}
     * @memberof JsonReportCheckWithState
     */
    einreichungsNotiz?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonReportCheckWithState
     */
    pruefungsNotiz?: string;
    /**
     * 
     * @type {BenutzerEreignis}
     * @memberof JsonReportCheckWithState
     */
    geprueft?: BenutzerEreignis;
    /**
     * Gibt die maximale Einstufung der Prüfmeldungen zurück.
     * @type {number}
     * @memberof JsonReportCheckWithState
     */
    maxEinstufung: number;
    /**
     * 
     * @type {Array<JsonReportError>}
     * @memberof JsonReportCheckWithState
     */
    pruefMeldungen: Array<JsonReportError>;
    /**
     * 
     * @type {string}
     * @memberof JsonReportCheckWithState
     */
    vergleichZuEinreichung?: JsonReportCheckWithStateVergleichZuEinreichungEnum;
}


/**
 * @export
 */
export const JsonReportCheckWithStateVergleichZuEinreichungEnum = {
    Gleich: 'GLEICH',
    Korrigiert: 'KORRIGIERT',
    Abweichung: 'ABWEICHUNG'
} as const;
export type JsonReportCheckWithStateVergleichZuEinreichungEnum = typeof JsonReportCheckWithStateVergleichZuEinreichungEnum[keyof typeof JsonReportCheckWithStateVergleichZuEinreichungEnum];


/**
 * Check if a given object implements the JsonReportCheckWithState interface.
 */
export function instanceOfJsonReportCheckWithState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "maxEinstufung" in value;
    isInstance = isInstance && "pruefMeldungen" in value;

    return isInstance;
}

export function JsonReportCheckWithStateFromJSON(json: any): JsonReportCheckWithState {
    return JsonReportCheckWithStateFromJSONTyped(json, false);
}

export function JsonReportCheckWithStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReportCheckWithState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtsNummer': json['BerichtsNummer'],
        'berichtsID': !exists(json, 'BerichtsID') ? undefined : json['BerichtsID'],
        'status': !exists(json, 'Status') ? undefined : BerichtsStatusFromJSON(json['Status']),
        'einreichungsNotiz': !exists(json, 'EinreichungsNotiz') ? undefined : json['EinreichungsNotiz'],
        'pruefungsNotiz': !exists(json, 'PruefungsNotiz') ? undefined : json['PruefungsNotiz'],
        'geprueft': !exists(json, 'Geprueft') ? undefined : BenutzerEreignisFromJSON(json['Geprueft']),
        'maxEinstufung': json['MaxEinstufung'],
        'pruefMeldungen': ((json['PruefMeldungen'] as Array<any>).map(JsonReportErrorFromJSON)),
        'vergleichZuEinreichung': !exists(json, 'VergleichZuEinreichung') ? undefined : json['VergleichZuEinreichung'],
    };
}

export function JsonReportCheckWithStateToJSON(value?: JsonReportCheckWithState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtsNummer': value.berichtsNummer,
        'BerichtsID': value.berichtsID,
        'Status': BerichtsStatusToJSON(value.status),
        'EinreichungsNotiz': value.einreichungsNotiz,
        'PruefungsNotiz': value.pruefungsNotiz,
        'Geprueft': BenutzerEreignisToJSON(value.geprueft),
        'MaxEinstufung': value.maxEinstufung,
        'PruefMeldungen': ((value.pruefMeldungen as Array<any>).map(JsonReportErrorToJSON)),
        'VergleichZuEinreichung': value.vergleichZuEinreichung,
    };
}

