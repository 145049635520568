/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EintragZuordnung } from './EintragZuordnung';
import {
    EintragZuordnungFromJSON,
    EintragZuordnungFromJSONTyped,
    EintragZuordnungToJSON,
} from './EintragZuordnung';
import type { ZuordnungTyp } from './ZuordnungTyp';
import {
    ZuordnungTypFromJSON,
    ZuordnungTypFromJSONTyped,
    ZuordnungTypToJSON,
} from './ZuordnungTyp';

/**
 * 
 * @export
 * @interface EintragsZuordnungen
 */
export interface EintragsZuordnungen {
    /**
     * 
     * @type {ZuordnungTyp}
     * @memberof EintragsZuordnungen
     */
    zuordnungsTyp: ZuordnungTyp;
    /**
     * 
     * @type {Array<EintragZuordnung>}
     * @memberof EintragsZuordnungen
     */
    zuordnungen: Array<EintragZuordnung>;
}

/**
 * Check if a given object implements the EintragsZuordnungen interface.
 */
export function instanceOfEintragsZuordnungen(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "zuordnungsTyp" in value;
    isInstance = isInstance && "zuordnungen" in value;

    return isInstance;
}

export function EintragsZuordnungenFromJSON(json: any): EintragsZuordnungen {
    return EintragsZuordnungenFromJSONTyped(json, false);
}

export function EintragsZuordnungenFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintragsZuordnungen {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zuordnungsTyp': ZuordnungTypFromJSON(json['ZuordnungsTyp']),
        'zuordnungen': ((json['Zuordnungen'] as Array<any>).map(EintragZuordnungFromJSON)),
    };
}

export function EintragsZuordnungenToJSON(value?: EintragsZuordnungen | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ZuordnungsTyp': ZuordnungTypToJSON(value.zuordnungsTyp),
        'Zuordnungen': ((value.zuordnungen as Array<any>).map(EintragZuordnungToJSON)),
    };
}

