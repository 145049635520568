/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NacharbeitsAuftrag } from './NacharbeitsAuftrag';
import {
    NacharbeitsAuftragFromJSON,
    NacharbeitsAuftragFromJSONTyped,
    NacharbeitsAuftragToJSON,
} from './NacharbeitsAuftrag';

/**
 * 
 * @export
 * @interface AbnahmeSetzenRequest
 */
export interface AbnahmeSetzenRequest {
    /**
     * Zeitstempel des Abnahmedatums.
     * @type {string}
     * @memberof AbnahmeSetzenRequest
     */
    abnahmedatum: string;
    /**
     * Das unterzeichnete Abnahmeprotokoll.
     * @type {string}
     * @memberof AbnahmeSetzenRequest
     */
    protokoll: string;
    /**
     * Die Abrufbestellnummer des Berichts, sofern bereits bekannt.
     * @type {string}
     * @memberof AbnahmeSetzenRequest
     */
    abrufbestellnummer?: string;
    /**
     * Gibt an ob der Bericht bereits fakturiert (verrechnet) werden kann.
     * @type {boolean}
     * @memberof AbnahmeSetzenRequest
     */
    istVerrechenbar: boolean;
    /**
     * 
     * @type {NacharbeitsAuftrag}
     * @memberof AbnahmeSetzenRequest
     */
    nacharbeitsAuftrag?: NacharbeitsAuftrag;
}

/**
 * Check if a given object implements the AbnahmeSetzenRequest interface.
 */
export function instanceOfAbnahmeSetzenRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "abnahmedatum" in value;
    isInstance = isInstance && "protokoll" in value;
    isInstance = isInstance && "istVerrechenbar" in value;

    return isInstance;
}

export function AbnahmeSetzenRequestFromJSON(json: any): AbnahmeSetzenRequest {
    return AbnahmeSetzenRequestFromJSONTyped(json, false);
}

export function AbnahmeSetzenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbnahmeSetzenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abnahmedatum': json['Abnahmedatum'],
        'protokoll': json['Protokoll'],
        'abrufbestellnummer': !exists(json, 'Abrufbestellnummer') ? undefined : json['Abrufbestellnummer'],
        'istVerrechenbar': json['IstVerrechenbar'],
        'nacharbeitsAuftrag': !exists(json, 'NacharbeitsAuftrag') ? undefined : NacharbeitsAuftragFromJSON(json['NacharbeitsAuftrag']),
    };
}

export function AbnahmeSetzenRequestToJSON(value?: AbnahmeSetzenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Abnahmedatum': value.abnahmedatum,
        'Protokoll': value.protokoll,
        'Abrufbestellnummer': value.abrufbestellnummer,
        'IstVerrechenbar': value.istVerrechenbar,
        'NacharbeitsAuftrag': NacharbeitsAuftragToJSON(value.nacharbeitsAuftrag),
    };
}

