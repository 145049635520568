/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractPosition
 */
export interface ContractPosition {
    /**
     * 
     * @type {number}
     * @memberof ContractPosition
     */
    poolPositionId: number;
    /**
     * 
     * @type {string}
     * @memberof ContractPosition
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractPosition
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ContractPosition
     */
    unit: string;
    /**
     * Milli-euros; divide by 1000 for euro price
     * @type {number}
     * @memberof ContractPosition
     */
    price: number;
    /**
     * If true, will render 'optional' in price column of offer and not count to total sum.
     * @type {boolean}
     * @memberof ContractPosition
     */
    optionalInOffer: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractPosition
     */
    amountInOffer: number;
}

/**
 * Check if a given object implements the ContractPosition interface.
 */
export function instanceOfContractPosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "poolPositionId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "optionalInOffer" in value;
    isInstance = isInstance && "amountInOffer" in value;

    return isInstance;
}

export function ContractPositionFromJSON(json: any): ContractPosition {
    return ContractPositionFromJSONTyped(json, false);
}

export function ContractPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'poolPositionId': json['poolPositionId'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': json['description'],
        'unit': json['unit'],
        'price': json['price'],
        'optionalInOffer': json['optionalInOffer'],
        'amountInOffer': json['amountInOffer'],
    };
}

export function ContractPositionToJSON(value?: ContractPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'poolPositionId': value.poolPositionId,
        'code': value.code,
        'description': value.description,
        'unit': value.unit,
        'price': value.price,
        'optionalInOffer': value.optionalInOffer,
        'amountInOffer': value.amountInOffer,
    };
}

