/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiFehler,
  DbbgEinsatzEinreichenDaten,
  DbbgEinsatzGruppe,
  DbbgEinsatzGruppeAuswahlElement,
  DbbgEinsatzOperation,
  DbbgEinsatzOperationTyp,
  DbbgEinsatzOperationenPruefungsergebnis,
  DbbgRailiumEinsatz,
} from '../models/index';
import {
    ApiFehlerFromJSON,
    ApiFehlerToJSON,
    DbbgEinsatzEinreichenDatenFromJSON,
    DbbgEinsatzEinreichenDatenToJSON,
    DbbgEinsatzGruppeFromJSON,
    DbbgEinsatzGruppeToJSON,
    DbbgEinsatzGruppeAuswahlElementFromJSON,
    DbbgEinsatzGruppeAuswahlElementToJSON,
    DbbgEinsatzOperationFromJSON,
    DbbgEinsatzOperationToJSON,
    DbbgEinsatzOperationTypFromJSON,
    DbbgEinsatzOperationTypToJSON,
    DbbgEinsatzOperationenPruefungsergebnisFromJSON,
    DbbgEinsatzOperationenPruefungsergebnisToJSON,
    DbbgRailiumEinsatzFromJSON,
    DbbgRailiumEinsatzToJSON,
} from '../models/index';

export interface DbbgEinsatzDatenLadenRequest {
    railiumEinsatzId: number;
}

export interface DbbgEinsatzEinreichenRequest {
    railiumEinsatzId: number;
    dbbgEinsatzEinreichenDaten?: DbbgEinsatzEinreichenDaten;
}

export interface DbbgEinsatzGruppeRequest {
    berichtId: number;
}

export interface DbbgEinsatzGruppeAendernRequest {
    dbbgEinsatzGruppe?: DbbgEinsatzGruppe;
}

export interface DbbgEinsatzGruppeEinreichenRequest {
    berichtId: number;
    fehlerIgnorieren?: boolean;
}

export interface DbbgEinsatzGruppePruefenRequest {
    berichtId: number;
}

export interface DbbgEinsatzGruppeVorerfassenRequest {
    berichtId: number;
}

export interface DbbgEinsatzGruppeVorerfassungAblehnenRequest {
    berichtId: number;
    ablehnungsgrund: string;
}

export interface DbbgEinsatzKandidatenLadenRequest {
    von: string;
    bis: string;
    maschinenGruppe: string;
}

export interface DbbgEinsatzOperationAendernRequest {
    dbbgEinsatzOperation?: DbbgEinsatzOperation;
}

export interface DbbgEinsatzOperationLoeschenRequest {
    operationsId: number;
}

export interface DbbgEinsatzOperationenRequest {
    railiumEinsatzId: number;
}

export interface DbbgEinsatzPdfDownloadRequest {
    railiumEinsatzId: number;
}

export interface DbbgEinsatzPdfUrlRequest {
    railiumEinsatzId: number;
}

export interface DbbgEinsatzPruefenRequest {
    railiumEinsatzId: number;
}

export interface DbbgFaploHochladenRequest {
    berichtId: number;
    body?: string;
}

export interface DbbgFaploUrlAbholenRequest {
    berichtId: number;
}

/**
 * 
 */
export class DbbgApi extends runtime.BaseAPI {

    /**
     * Lädt die Daten eines Railium-Einsatzes
     */
    async dbbgEinsatzDatenLadenRaw(requestParameters: DbbgEinsatzDatenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgRailiumEinsatz>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzDatenLaden.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgRailiumEinsatzFromJSON(jsonValue));
    }

    /**
     * Lädt die Daten eines Railium-Einsatzes
     */
    async dbbgEinsatzDatenLaden(requestParameters: DbbgEinsatzDatenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgRailiumEinsatz> {
        const response = await this.dbbgEinsatzDatenLadenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reicht einen Bericht für einen Railium-Einsatz ein. 
     */
    async dbbgEinsatzEinreichenRaw(requestParameters: DbbgEinsatzEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgRailiumEinsatz>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzEinreichen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgEinsatzEinreichenDatenToJSON(requestParameters.dbbgEinsatzEinreichenDaten),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgRailiumEinsatzFromJSON(jsonValue));
    }

    /**
     * Reicht einen Bericht für einen Railium-Einsatz ein. 
     */
    async dbbgEinsatzEinreichen(requestParameters: DbbgEinsatzEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgRailiumEinsatz> {
        const response = await this.dbbgEinsatzEinreichenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt die DBBG Einsatzgruppe (Bericht) für eine ID zurück.
     */
    async dbbgEinsatzGruppeRaw(requestParameters: DbbgEinsatzGruppeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgEinsatzGruppe>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgEinsatzGruppe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgEinsatzGruppeFromJSON(jsonValue));
    }

    /**
     * Gibt die DBBG Einsatzgruppe (Bericht) für eine ID zurück.
     */
    async dbbgEinsatzGruppe(requestParameters: DbbgEinsatzGruppeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgEinsatzGruppe> {
        const response = await this.dbbgEinsatzGruppeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Speichert Änderungen einer DBBG Einsatzgruppe.
     */
    async dbbgEinsatzGruppeAendernRaw(requestParameters: DbbgEinsatzGruppeAendernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgEinsatzGruppeToJSON(requestParameters.dbbgEinsatzGruppe),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert Änderungen einer DBBG Einsatzgruppe.
     */
    async dbbgEinsatzGruppeAendern(requestParameters: DbbgEinsatzGruppeAendernRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgEinsatzGruppeAendernRaw(requestParameters, initOverrides);
    }

    /**
     * Reicht eine DBBG Einsatzgruppe inklusive Einsatzzeiten ein.
     */
    async dbbgEinsatzGruppeEinreichenRaw(requestParameters: DbbgEinsatzGruppeEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgEinsatzGruppeEinreichen.');
        }

        const queryParameters: any = {};

        if (requestParameters.fehlerIgnorieren !== undefined) {
            queryParameters['fehlerIgnorieren'] = requestParameters.fehlerIgnorieren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}/submit`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reicht eine DBBG Einsatzgruppe inklusive Einsatzzeiten ein.
     */
    async dbbgEinsatzGruppeEinreichen(requestParameters: DbbgEinsatzGruppeEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgEinsatzGruppeEinreichenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt für jeden Railium Einsatz ein Prüfungsergebnis zurück.
     */
    async dbbgEinsatzGruppePruefenRaw(requestParameters: DbbgEinsatzGruppePruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgEinsatzOperationenPruefungsergebnis>>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgEinsatzGruppePruefen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/reports/{BerichtId}/check`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgEinsatzOperationenPruefungsergebnisFromJSON));
    }

    /**
     * Gibt für jeden Railium Einsatz ein Prüfungsergebnis zurück.
     */
    async dbbgEinsatzGruppePruefen(requestParameters: DbbgEinsatzGruppePruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgEinsatzOperationenPruefungsergebnis>> {
        const response = await this.dbbgEinsatzGruppePruefenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reicht Berichtsdaten einer DBBG Gruppe ohne Einsatzzeiten ein.
     */
    async dbbgEinsatzGruppeVorerfassenRaw(requestParameters: DbbgEinsatzGruppeVorerfassenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgEinsatzGruppeVorerfassen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}/presubmit`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reicht Berichtsdaten einer DBBG Gruppe ohne Einsatzzeiten ein.
     */
    async dbbgEinsatzGruppeVorerfassen(requestParameters: DbbgEinsatzGruppeVorerfassenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgEinsatzGruppeVorerfassenRaw(requestParameters, initOverrides);
    }

    /**
     * Lehnt vorerfasste Berichtsdaten einer DBBG Gruppe (ohne Einsatzzeiten) ab.
     */
    async dbbgEinsatzGruppeVorerfassungAblehnenRaw(requestParameters: DbbgEinsatzGruppeVorerfassungAblehnenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgEinsatzGruppeVorerfassungAblehnen.');
        }

        if (requestParameters.ablehnungsgrund === null || requestParameters.ablehnungsgrund === undefined) {
            throw new runtime.RequiredError('ablehnungsgrund','Required parameter requestParameters.ablehnungsgrund was null or undefined when calling dbbgEinsatzGruppeVorerfassungAblehnen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.ablehnungsgrund !== undefined) {
            formParams.append('Ablehnungsgrund', requestParameters.ablehnungsgrund as any);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}/presubmit/reject`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lehnt vorerfasste Berichtsdaten einer DBBG Gruppe (ohne Einsatzzeiten) ab.
     */
    async dbbgEinsatzGruppeVorerfassungAblehnen(requestParameters: DbbgEinsatzGruppeVorerfassungAblehnenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgEinsatzGruppeVorerfassungAblehnenRaw(requestParameters, initOverrides);
    }

    /**
     * Railium Einsätze für Sortierfeldnummer laden
     */
    async dbbgEinsatzKandidatenLadenRaw(requestParameters: DbbgEinsatzKandidatenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgRailiumEinsatz>>> {
        if (requestParameters.von === null || requestParameters.von === undefined) {
            throw new runtime.RequiredError('von','Required parameter requestParameters.von was null or undefined when calling dbbgEinsatzKandidatenLaden.');
        }

        if (requestParameters.bis === null || requestParameters.bis === undefined) {
            throw new runtime.RequiredError('bis','Required parameter requestParameters.bis was null or undefined when calling dbbgEinsatzKandidatenLaden.');
        }

        if (requestParameters.maschinenGruppe === null || requestParameters.maschinenGruppe === undefined) {
            throw new runtime.RequiredError('maschinenGruppe','Required parameter requestParameters.maschinenGruppe was null or undefined when calling dbbgEinsatzKandidatenLaden.');
        }

        const queryParameters: any = {};

        if (requestParameters.von !== undefined) {
            queryParameters['Von'] = requestParameters.von;
        }

        if (requestParameters.bis !== undefined) {
            queryParameters['Bis'] = requestParameters.bis;
        }

        if (requestParameters.maschinenGruppe !== undefined) {
            queryParameters['MaschinenGruppe'] = requestParameters.maschinenGruppe;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/candidates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgRailiumEinsatzFromJSON));
    }

    /**
     * Railium Einsätze für Sortierfeldnummer laden
     */
    async dbbgEinsatzKandidatenLaden(requestParameters: DbbgEinsatzKandidatenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgRailiumEinsatz>> {
        const response = await this.dbbgEinsatzKandidatenLadenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Erstellt oder speichert Änderungen einer DBBG-Einsatzgruppen-Operation.
     */
    async dbbgEinsatzOperationAendernRaw(requestParameters: DbbgEinsatzOperationAendernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgEinsatzOperation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/operation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgEinsatzOperationToJSON(requestParameters.dbbgEinsatzOperation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgEinsatzOperationFromJSON(jsonValue));
    }

    /**
     * Erstellt oder speichert Änderungen einer DBBG-Einsatzgruppen-Operation.
     */
    async dbbgEinsatzOperationAendern(requestParameters: DbbgEinsatzOperationAendernRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgEinsatzOperation> {
        const response = await this.dbbgEinsatzOperationAendernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht eine DbbgEinsatzGruppeOperation.
     */
    async dbbgEinsatzOperationLoeschenRaw(requestParameters: DbbgEinsatzOperationLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.operationsId === null || requestParameters.operationsId === undefined) {
            throw new runtime.RequiredError('operationsId','Required parameter requestParameters.operationsId was null or undefined when calling dbbgEinsatzOperationLoeschen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/operation/{OperationsId}`.replace(`{${"OperationsId"}}`, encodeURIComponent(String(requestParameters.operationsId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht eine DbbgEinsatzGruppeOperation.
     */
    async dbbgEinsatzOperationLoeschen(requestParameters: DbbgEinsatzOperationLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgEinsatzOperationLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt alle möglichen Typen von DBBG-Einsatzgruppen-Operation zurück.
     */
    async dbbgEinsatzOperationTypenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgEinsatzOperationTyp>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/operationType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgEinsatzOperationTypFromJSON));
    }

    /**
     * Gibt alle möglichen Typen von DBBG-Einsatzgruppen-Operation zurück.
     */
    async dbbgEinsatzOperationTypen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgEinsatzOperationTyp>> {
        const response = await this.dbbgEinsatzOperationTypenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit Einsatzgruppen-Operationen für Eine Einsatzgruppe zurück.
     */
    async dbbgEinsatzOperationenRaw(requestParameters: DbbgEinsatzOperationenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgEinsatzOperation>>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzOperationen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}/operations`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgEinsatzOperationFromJSON));
    }

    /**
     * Gibt eine Liste mit Einsatzgruppen-Operationen für Eine Einsatzgruppe zurück.
     */
    async dbbgEinsatzOperationen(requestParameters: DbbgEinsatzOperationenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgEinsatzOperation>> {
        const response = await this.dbbgEinsatzOperationenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lädt den PDF-Bericht herunter.
     */
    async dbbgEinsatzPdfDownloadRaw(requestParameters: DbbgEinsatzPdfDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzPdfDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}/pdf`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt den PDF-Bericht herunter.
     */
    async dbbgEinsatzPdfDownload(requestParameters: DbbgEinsatzPdfDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dbbgEinsatzPdfDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Holt die URL für die PDF-Datei eines Einsatzes ab.
     */
    async dbbgEinsatzPdfUrlRaw(requestParameters: DbbgEinsatzPdfUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzPdfUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}/url`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Holt die URL für die PDF-Datei eines Einsatzes ab.
     */
    async dbbgEinsatzPdfUrl(requestParameters: DbbgEinsatzPdfUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dbbgEinsatzPdfUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt für den Railium Einsatz ein Prüfungsergebnis zurück.
     */
    async dbbgEinsatzPruefenRaw(requestParameters: DbbgEinsatzPruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgEinsatzOperationenPruefungsergebnis>> {
        if (requestParameters.railiumEinsatzId === null || requestParameters.railiumEinsatzId === undefined) {
            throw new runtime.RequiredError('railiumEinsatzId','Required parameter requestParameters.railiumEinsatzId was null or undefined when calling dbbgEinsatzPruefen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/railium/{RailiumEinsatzId}/check`.replace(`{${"RailiumEinsatzId"}}`, encodeURIComponent(String(requestParameters.railiumEinsatzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgEinsatzOperationenPruefungsergebnisFromJSON(jsonValue));
    }

    /**
     * Gibt für den Railium Einsatz ein Prüfungsergebnis zurück.
     */
    async dbbgEinsatzPruefen(requestParameters: DbbgEinsatzPruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgEinsatzOperationenPruefungsergebnis> {
        const response = await this.dbbgEinsatzPruefenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Speichert einen Faplo zu einem DBBG Bericht.
     * Speichert einen Faplo
     */
    async dbbgFaploHochladenRaw(requestParameters: DbbgFaploHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgFaploHochladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}/faplo`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert einen Faplo zu einem DBBG Bericht.
     * Speichert einen Faplo
     */
    async dbbgFaploHochladen(requestParameters: DbbgFaploHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgFaploHochladenRaw(requestParameters, initOverrides);
    }

    /**
     * Holt die Download-URL für den Faplo ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt die Download-URL für den Faplo ab.
     */
    async dbbgFaploUrlAbholenRaw(requestParameters: DbbgFaploUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.berichtId === null || requestParameters.berichtId === undefined) {
            throw new runtime.RequiredError('berichtId','Required parameter requestParameters.berichtId was null or undefined when calling dbbgFaploUrlAbholen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg/{BerichtId}/faploUrl`.replace(`{${"BerichtId"}}`, encodeURIComponent(String(requestParameters.berichtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Holt die Download-URL für den Faplo ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt die Download-URL für den Faplo ab.
     */
    async dbbgFaploUrlAbholen(requestParameters: DbbgFaploUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dbbgFaploUrlAbholenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit offenen (fehlende Tagesberichte bei zugeordneten Railium-Einsätzen) Einsatzgruppen zurück.
     */
    async dbbgOffeneEinsatzGruppenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgEinsatzGruppeAuswahlElement>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/dbbg`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgEinsatzGruppeAuswahlElementFromJSON));
    }

    /**
     * Gibt eine Liste mit offenen (fehlende Tagesberichte bei zugeordneten Railium-Einsätzen) Einsatzgruppen zurück.
     */
    async dbbgOffeneEinsatzGruppen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgEinsatzGruppeAuswahlElement>> {
        const response = await this.dbbgOffeneEinsatzGruppenRaw(initOverrides);
        return await response.value();
    }

}
