import { computed, getCurrentInstance } from "vue";
import { useOauthPermissionsInfo } from "./useOauthPermissionsInfo";
import { oauthRoles } from "@/authConfig";

export function useOauthRoles(instance = getCurrentInstance()) {
  const oauthPermissions = useOauthPermissionsInfo(instance);

  const isUserGeneratorAdmin = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.GENERATOR_ADMIN),
  );
  const isUserReportAdmin = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.REPORT_ADMIN),
  );
  const canUserReadReports = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_READ,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserSubmitReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_SUBMIT,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserSubmitDbbgReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_SUBMIT_DBBG,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserReviewReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_REVIEW,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserBillReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_BILLING,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserCommunicateExternal = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_EXTERNAL,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserApproveReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_APPROVAL,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserAssignReports = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_ASSIGNMENTS,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserDownloadOebbMonthlyBackups = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_MONTHLY_BACKUP,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserManageDbbg = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_MANAGE_DBBG,
      oauthRoles.REPORT_ADMIN,
    ),
  );

  return {
    isUserGeneratorAdmin,
    isUserReportAdmin,
    canUserReadReports,
    canUserSubmitReport,
    canUserSubmitDbbgReport,
    canUserReviewReport,
    canUserBillReport,
    canUserCommunicateExternal,
    canUserApproveReport,
    canUserAssignReports,
    canUserDownloadOebbMonthlyBackups,
    canUserManageDbbg,
  };
}
