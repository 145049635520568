/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen Connector
 * RVC Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userPrincipalName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    department?: string;
}

/**
 * Check if a given object implements the UserInfo interface.
 */
export function instanceOfUserInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'preferredLanguage': !exists(json, 'preferredLanguage') ? undefined : json['preferredLanguage'],
        'userPrincipalName': !exists(json, 'userPrincipalName') ? undefined : json['userPrincipalName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'department': !exists(json, 'department') ? undefined : json['department'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mobilePhone': value.mobilePhone,
        'displayName': value.displayName,
        'mail': value.mail,
        'jobTitle': value.jobTitle,
        'preferredLanguage': value.preferredLanguage,
        'userPrincipalName': value.userPrincipalName,
        'companyName': value.companyName,
        'department': value.department,
    };
}

