/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PdfPartMeta
 */
export interface PdfPartMeta {
    /**
     * 
     * @type {number}
     * @memberof PdfPartMeta
     */
    ersteSeite: number;
    /**
     * 
     * @type {number}
     * @memberof PdfPartMeta
     */
    letzteSeite: number;
}

/**
 * Check if a given object implements the PdfPartMeta interface.
 */
export function instanceOfPdfPartMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ersteSeite" in value;
    isInstance = isInstance && "letzteSeite" in value;

    return isInstance;
}

export function PdfPartMetaFromJSON(json: any): PdfPartMeta {
    return PdfPartMetaFromJSONTyped(json, false);
}

export function PdfPartMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfPartMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ersteSeite': json['ErsteSeite'],
        'letzteSeite': json['LetzteSeite'],
    };
}

export function PdfPartMetaToJSON(value?: PdfPartMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ErsteSeite': value.ersteSeite,
        'LetzteSeite': value.letzteSeite,
    };
}

