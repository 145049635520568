import {
  ComponentInternalInstance,
  Ref,
  getCurrentInstance,
  ref,
  watch,
} from "vue";
import { useMsal } from "./useMsal";

export function useIsAuthenticated(
  instance: ComponentInternalInstance | null = getCurrentInstance(),
): Ref<boolean> {
  const { accounts } = useMsal(instance);
  const isAuthenticated = ref(accounts.value.length > 0);

  watch(accounts, () => {
    isAuthenticated.value = accounts.value.length > 0;
  });

  return isAuthenticated;
}
