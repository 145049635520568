/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerichtAORV } from './BerichtAORV';
import {
    BerichtAORVFromJSON,
    BerichtAORVFromJSONTyped,
    BerichtAORVToJSON,
} from './BerichtAORV';
import type { BerichtDBBG } from './BerichtDBBG';
import {
    BerichtDBBGFromJSON,
    BerichtDBBGFromJSONTyped,
    BerichtDBBGToJSON,
} from './BerichtDBBG';
import type { BerichtOEBB } from './BerichtOEBB';
import {
    BerichtOEBBFromJSON,
    BerichtOEBBFromJSONTyped,
    BerichtOEBBToJSON,
} from './BerichtOEBB';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';
import type { BerichtsTyp } from './BerichtsTyp';
import {
    BerichtsTypFromJSON,
    BerichtsTypFromJSONTyped,
    BerichtsTypToJSON,
} from './BerichtsTyp';
import type { EintragAbnahme } from './EintragAbnahme';
import {
    EintragAbnahmeFromJSON,
    EintragAbnahmeFromJSONTyped,
    EintragAbnahmeToJSON,
} from './EintragAbnahme';
import type { EintragDateien } from './EintragDateien';
import {
    EintragDateienFromJSON,
    EintragDateienFromJSONTyped,
    EintragDateienToJSON,
} from './EintragDateien';
import type { EintragTyp } from './EintragTyp';
import {
    EintragTypFromJSON,
    EintragTypFromJSONTyped,
    EintragTypToJSON,
} from './EintragTyp';
import type { Ereignis } from './Ereignis';
import {
    EreignisFromJSON,
    EreignisFromJSONTyped,
    EreignisToJSON,
} from './Ereignis';
import type { RailiumEinsatz } from './RailiumEinsatz';
import {
    RailiumEinsatzFromJSON,
    RailiumEinsatzFromJSONTyped,
    RailiumEinsatzToJSON,
} from './RailiumEinsatz';
import type { ZuordnungTyp } from './ZuordnungTyp';
import {
    ZuordnungTypFromJSON,
    ZuordnungTypFromJSONTyped,
    ZuordnungTypToJSON,
} from './ZuordnungTyp';

/**
 * 
 * @export
 * @interface Eintrag
 */
export interface Eintrag {
    /**
     * 
     * @type {EintragTyp}
     * @memberof Eintrag
     */
    eintragTyp: EintragTyp;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    iD: string;
    /**
     * 
     * @type {number}
     * @memberof Eintrag
     */
    vertragsnummer: number;
    /**
     * 
     * @type {number}
     * @memberof Eintrag
     */
    jahr: number;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    maschinengruppe: string;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    durchfuehrungVon: string;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    durchfuehrungBis: string;
    /**
     * 
     * @type {BerichtsTyp}
     * @memberof Eintrag
     */
    berichtsTyp?: BerichtsTyp;
    /**
     * 
     * @type {number}
     * @memberof Eintrag
     */
    berichtsnummer?: number;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    einsatzort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Eintrag
     */
    sofortAbnahme?: boolean;
    /**
     * 
     * @type {BerichtOEBB}
     * @memberof Eintrag
     */
    berichtOEBB?: BerichtOEBB;
    /**
     * 
     * @type {BerichtAORV}
     * @memberof Eintrag
     */
    berichtAORV?: BerichtAORV;
    /**
     * 
     * @type {BerichtDBBG}
     * @memberof Eintrag
     */
    berichtDBBG?: BerichtDBBG;
    /**
     * 
     * @type {RailiumEinsatz}
     * @memberof Eintrag
     */
    railiumEinsatz?: RailiumEinsatz;
    /**
     * 
     * @type {Array<Ereignis>}
     * @memberof Eintrag
     */
    ereignisse?: Array<Ereignis>;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    vertragsname: string;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    firmenname: string;
    /**
     * 
     * @type {EintragDateien}
     * @memberof Eintrag
     */
    dateien?: EintragDateien;
    /**
     * 
     * @type {ZuordnungTyp}
     * @memberof Eintrag
     */
    berichtsZuordnung?: ZuordnungTyp;
    /**
     * Anzahl der zugeordneten Railium Einsätze.
     * @type {number}
     * @memberof Eintrag
     */
    berichtsZuordnungen?: number;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof Eintrag
     */
    berichtsStatus?: BerichtsStatus;
    /**
     * 
     * @type {EintragAbnahme}
     * @memberof Eintrag
     */
    abnahme?: EintragAbnahme;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    rechnungsnummer?: string;
    /**
     * Ganze Zahl durch 100 zu dividieren für Eurobetrag.
     * @type {number}
     * @memberof Eintrag
     */
    rechnungbetragNetto?: number;
    /**
     * 
     * @type {string}
     * @memberof Eintrag
     */
    abrufbestellnummer?: string;
}

/**
 * Check if a given object implements the Eintrag interface.
 */
export function instanceOfEintrag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eintragTyp" in value;
    isInstance = isInstance && "iD" in value;
    isInstance = isInstance && "vertragsnummer" in value;
    isInstance = isInstance && "jahr" in value;
    isInstance = isInstance && "maschinengruppe" in value;
    isInstance = isInstance && "durchfuehrungVon" in value;
    isInstance = isInstance && "durchfuehrungBis" in value;
    isInstance = isInstance && "vertragsname" in value;
    isInstance = isInstance && "firmenname" in value;

    return isInstance;
}

export function EintragFromJSON(json: any): Eintrag {
    return EintragFromJSONTyped(json, false);
}

export function EintragFromJSONTyped(json: any, ignoreDiscriminator: boolean): Eintrag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eintragTyp': EintragTypFromJSON(json['EintragTyp']),
        'iD': json['ID'],
        'vertragsnummer': json['Vertragsnummer'],
        'jahr': json['Jahr'],
        'maschinengruppe': json['Maschinengruppe'],
        'durchfuehrungVon': json['DurchfuehrungVon'],
        'durchfuehrungBis': json['DurchfuehrungBis'],
        'berichtsTyp': !exists(json, 'BerichtsTyp') ? undefined : BerichtsTypFromJSON(json['BerichtsTyp']),
        'berichtsnummer': !exists(json, 'Berichtsnummer') ? undefined : json['Berichtsnummer'],
        'einsatzort': !exists(json, 'Einsatzort') ? undefined : json['Einsatzort'],
        'sofortAbnahme': !exists(json, 'SofortAbnahme') ? undefined : json['SofortAbnahme'],
        'berichtOEBB': !exists(json, 'BerichtOEBB') ? undefined : BerichtOEBBFromJSON(json['BerichtOEBB']),
        'berichtAORV': !exists(json, 'BerichtAORV') ? undefined : BerichtAORVFromJSON(json['BerichtAORV']),
        'berichtDBBG': !exists(json, 'BerichtDBBG') ? undefined : BerichtDBBGFromJSON(json['BerichtDBBG']),
        'railiumEinsatz': !exists(json, 'RailiumEinsatz') ? undefined : RailiumEinsatzFromJSON(json['RailiumEinsatz']),
        'ereignisse': !exists(json, 'Ereignisse') ? undefined : ((json['Ereignisse'] as Array<any>).map(EreignisFromJSON)),
        'vertragsname': json['Vertragsname'],
        'firmenname': json['Firmenname'],
        'dateien': !exists(json, 'Dateien') ? undefined : EintragDateienFromJSON(json['Dateien']),
        'berichtsZuordnung': !exists(json, 'BerichtsZuordnung') ? undefined : ZuordnungTypFromJSON(json['BerichtsZuordnung']),
        'berichtsZuordnungen': !exists(json, 'BerichtsZuordnungen') ? undefined : json['BerichtsZuordnungen'],
        'berichtsStatus': !exists(json, 'BerichtsStatus') ? undefined : BerichtsStatusFromJSON(json['BerichtsStatus']),
        'abnahme': !exists(json, 'Abnahme') ? undefined : EintragAbnahmeFromJSON(json['Abnahme']),
        'rechnungsnummer': !exists(json, 'Rechnungsnummer') ? undefined : json['Rechnungsnummer'],
        'rechnungbetragNetto': !exists(json, 'RechnungbetragNetto') ? undefined : json['RechnungbetragNetto'],
        'abrufbestellnummer': !exists(json, 'Abrufbestellnummer') ? undefined : json['Abrufbestellnummer'],
    };
}

export function EintragToJSON(value?: Eintrag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EintragTyp': EintragTypToJSON(value.eintragTyp),
        'ID': value.iD,
        'Vertragsnummer': value.vertragsnummer,
        'Jahr': value.jahr,
        'Maschinengruppe': value.maschinengruppe,
        'DurchfuehrungVon': value.durchfuehrungVon,
        'DurchfuehrungBis': value.durchfuehrungBis,
        'BerichtsTyp': BerichtsTypToJSON(value.berichtsTyp),
        'Berichtsnummer': value.berichtsnummer,
        'Einsatzort': value.einsatzort,
        'SofortAbnahme': value.sofortAbnahme,
        'BerichtOEBB': BerichtOEBBToJSON(value.berichtOEBB),
        'BerichtAORV': BerichtAORVToJSON(value.berichtAORV),
        'BerichtDBBG': BerichtDBBGToJSON(value.berichtDBBG),
        'RailiumEinsatz': RailiumEinsatzToJSON(value.railiumEinsatz),
        'Ereignisse': value.ereignisse === undefined ? undefined : ((value.ereignisse as Array<any>).map(EreignisToJSON)),
        'Vertragsname': value.vertragsname,
        'Firmenname': value.firmenname,
        'Dateien': EintragDateienToJSON(value.dateien),
        'BerichtsZuordnung': ZuordnungTypToJSON(value.berichtsZuordnung),
        'BerichtsZuordnungen': value.berichtsZuordnungen,
        'BerichtsStatus': BerichtsStatusToJSON(value.berichtsStatus),
        'Abnahme': EintragAbnahmeToJSON(value.abnahme),
        'Rechnungsnummer': value.rechnungsnummer,
        'RechnungbetragNetto': value.rechnungbetragNetto,
        'Abrufbestellnummer': value.abrufbestellnummer,
    };
}

