/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Notiz
 */
export interface Notiz {
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    berichtsID: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    am: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    notiz: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    obsoletVon?: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    obsoletAm?: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    geloeschtVon?: string;
    /**
     * 
     * @type {string}
     * @memberof Notiz
     */
    geloeschtAm?: string;
}

/**
 * Check if a given object implements the Notiz interface.
 */
export function instanceOfNotiz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtsID" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "am" in value;
    isInstance = isInstance && "notiz" in value;

    return isInstance;
}

export function NotizFromJSON(json: any): Notiz {
    return NotizFromJSONTyped(json, false);
}

export function NotizFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notiz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtsID': json['BerichtsID'],
        'von': json['Von'],
        'am': json['Am'],
        'notiz': json['Notiz'],
        'obsoletVon': !exists(json, 'ObsoletVon') ? undefined : json['ObsoletVon'],
        'obsoletAm': !exists(json, 'ObsoletAm') ? undefined : json['ObsoletAm'],
        'geloeschtVon': !exists(json, 'GeloeschtVon') ? undefined : json['GeloeschtVon'],
        'geloeschtAm': !exists(json, 'GeloeschtAm') ? undefined : json['GeloeschtAm'],
    };
}

export function NotizToJSON(value?: Notiz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtsID': value.berichtsID,
        'Von': value.von,
        'Am': value.am,
        'Notiz': value.notiz,
        'ObsoletVon': value.obsoletVon,
        'ObsoletAm': value.obsoletAm,
        'GeloeschtVon': value.geloeschtVon,
        'GeloeschtAm': value.geloeschtAm,
    };
}

