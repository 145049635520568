import { dayjs } from "element-plus";
import { computed } from "vue";

export const locale = computed(() => {
  if (dayjs.locale() == "de") {
    return "de-DE";
  } else {
    return "en-US";
  }
});
