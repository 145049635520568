/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EreignisTyp = {
    Create: 'CREATE',
    PreliminarySubmit: 'PRELIMINARY_SUBMIT',
    PreliminarySubmitReject: 'PRELIMINARY_SUBMIT_REJECT',
    Submit: 'SUBMIT',
    ApproveImmediate: 'APPROVE_IMMEDIATE',
    Review: 'REVIEW',
    ReviewOk: 'REVIEW_OK',
    ReviewReject: 'REVIEW_REJECT',
    ExternallyPassOn: 'EXTERNALLY_PASS_ON',
    ExternallyReceive: 'EXTERNALLY_RECEIVE',
    PrintApproval: 'PRINT_APPROVAL',
    ApproveNonBillable: 'APPROVE_NON_BILLABLE',
    ApproveBillable: 'APPROVE_BILLABLE',
    ReworkPending: 'REWORK_PENDING',
    ReworkDone: 'REWORK_DONE',
    OrderReference: 'ORDER_REFERENCE',
    InvoiceNumber: 'INVOICE_NUMBER',
    Invoice: 'INVOICE',
    SapChangeImplicit: 'SAP_CHANGE_IMPLICIT',
    SapChangeExplicit: 'SAP_CHANGE_EXPLICIT',
    Reset: 'RESET',
    Close: 'CLOSE'
} as const;
export type EreignisTyp = typeof EreignisTyp[keyof typeof EreignisTyp];


export function EreignisTypFromJSON(json: any): EreignisTyp {
    return EreignisTypFromJSONTyped(json, false);
}

export function EreignisTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): EreignisTyp {
    return json as EreignisTyp;
}

export function EreignisTypToJSON(value?: EreignisTyp | null): any {
    return value as any;
}

