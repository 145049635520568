import { App, Ref, ref, watch } from "vue";
import { msalPlugin } from "./msalPlugin";
import {
  CheckApi,
  Configuration as SubmitConfiguration,
  ConfigurationParameters,
  DbbgApi as DbbgApiSubmit,
  IdentityApi,
  SubmitApi,
} from "../generated/submit";
import {
  Configuration as ManagementConfiguration,
  DbbgApi as DbbgApiManagement,
  JobsApi,
  MasterDataApi,
  NotesApi,
  ReportsApi,
  ExportApi,
} from "../generated/management";
import {
  CompaniesApi,
  Configuration as GeneratorConfiguration,
  ContractsApi,
  LocationsApi,
  MachinegroupsApi,
  PositionsApi,
  SectionsApi,
} from "../generated/generator";
import { AccessToken, isValidAccessToken } from "./apiHelpers";
import { ApiMiddleware } from "./ApiMiddleware";
import {
  BaseApis,
  setupCallWhenTokenInitialized,
} from "@/plugins/baseApiPlugin";

export interface BackendApis extends BaseApis {
  check: CheckApi;
  submit: SubmitApi;
  submitDbbg: DbbgApiSubmit;
  identity: IdentityApi;
  jobs: JobsApi;
  notes: NotesApi;
  reports: ReportsApi;
  generator: {
    companies: CompaniesApi;
    contracts: ContractsApi;
    machineGroups: MachinegroupsApi;
    positions: PositionsApi;
    sections: SectionsApi;
    locations: LocationsApi;
  };
  export: ExportApi;
  masterData: MasterDataApi;
  dbbgApi: DbbgApiManagement;
}

export const apiPlugin = {
  install: (app: App, msalPlg: typeof msalPlugin) => {
    // Do stuff that should happen once

    app.config.globalProperties.$backendApiAccessToken = ref<AccessToken>();
    app.config.globalProperties.$backendApiAccessTokenLock = ref(false);

    const accessTokenToHeader: (at?: AccessToken) => string = (at) => {
      if (isValidAccessToken(at)) {
        return "Bearer " + at!.accessToken;
      } else {
        throw new Error("Missing access token");
      }
    };

    let configBase: ConfigurationParameters = {
      middleware: [new ApiMiddleware(app.config.globalProperties.$language)],
    };
    const submitConfig = new SubmitConfiguration({
      ...configBase,
      basePath: "/api/submit",
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$backendApiAccessToken.value,
        ),
    });
    const managementConfig = new ManagementConfiguration({
      ...configBase,
      basePath: "/api/management",
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$backendApiAccessToken.value,
        ),
    });
    const generatorConfig = new GeneratorConfiguration({
      ...configBase,
      basePath: "/api/generator",
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$backendApiAccessToken.value,
        ),
    });

    const tokenInitialized = ref(false);

    app.config.globalProperties.$backendApis = {
      check: new CheckApi(submitConfig),
      submit: new SubmitApi(submitConfig),
      submitDbbg: new DbbgApiSubmit(submitConfig),
      identity: new IdentityApi(submitConfig),
      jobs: new JobsApi(managementConfig),
      notes: new NotesApi(managementConfig),
      reports: new ReportsApi(managementConfig),
      export: new ExportApi(managementConfig),
      generator: {
        companies: new CompaniesApi(generatorConfig),
        contracts: new ContractsApi(generatorConfig),
        machineGroups: new MachinegroupsApi(generatorConfig),
        positions: new PositionsApi(generatorConfig),
        sections: new SectionsApi(generatorConfig),
        locations: new LocationsApi(generatorConfig),
      },
      masterData: new MasterDataApi(managementConfig),
      dbbgApi: new DbbgApiManagement(managementConfig),
      isTokenInitialized: tokenInitialized,
      isTokenCurrentlyValid: ref(false),
      callWhenTokenInitialized: setupCallWhenTokenInitialized(tokenInitialized),
    };
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $backendApiAccessToken: Ref<AccessToken | undefined>;
    $backendApis: BackendApis;
    $backendApiAccessTokenLock: Ref<boolean>;
  }
}
