/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Eintrag } from './Eintrag';
import {
    EintragFromJSON,
    EintragFromJSONTyped,
    EintragToJSON,
} from './Eintrag';

/**
 * 
 * @export
 * @interface EintraegeErgebnis
 */
export interface EintraegeErgebnis {
    /**
     * Anzahl der im Ergebnis enthaltenen Einträge.
     * @type {number}
     * @memberof EintraegeErgebnis
     */
    anzahlEintraege: number;
    /**
     * Anzahl der zur Verfügung stehenden Einträge.
     * @type {number}
     * @memberof EintraegeErgebnis
     */
    anzahlEintraegeGesamt: number;
    /**
     * 
     * @type {Array<Eintrag>}
     * @memberof EintraegeErgebnis
     */
    eintraege: Array<Eintrag>;
}

/**
 * Check if a given object implements the EintraegeErgebnis interface.
 */
export function instanceOfEintraegeErgebnis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "anzahlEintraege" in value;
    isInstance = isInstance && "anzahlEintraegeGesamt" in value;
    isInstance = isInstance && "eintraege" in value;

    return isInstance;
}

export function EintraegeErgebnisFromJSON(json: any): EintraegeErgebnis {
    return EintraegeErgebnisFromJSONTyped(json, false);
}

export function EintraegeErgebnisFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintraegeErgebnis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anzahlEintraege': json['AnzahlEintraege'],
        'anzahlEintraegeGesamt': json['AnzahlEintraegeGesamt'],
        'eintraege': ((json['Eintraege'] as Array<any>).map(EintragFromJSON)),
    };
}

export function EintraegeErgebnisToJSON(value?: EintraegeErgebnis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AnzahlEintraege': value.anzahlEintraege,
        'AnzahlEintraegeGesamt': value.anzahlEintraegeGesamt,
        'Eintraege': ((value.eintraege as Array<any>).map(EintragToJSON)),
    };
}

