/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportPdfResponseBodyBerichtDatenStrukturInfo } from './ReportPdfResponseBodyBerichtDatenStrukturInfo';
import {
    ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSON,
    ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSONTyped,
    ReportPdfResponseBodyBerichtDatenStrukturInfoToJSON,
} from './ReportPdfResponseBodyBerichtDatenStrukturInfo';

/**
 * 
 * @export
 * @interface ReportPdfResponseBodyBerichtDaten
 */
export interface ReportPdfResponseBodyBerichtDaten {
    /**
     * 
     * @type {number}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    vertragsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    kreditorNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    berichtsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    abnahmeDatum?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    vonDatum: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    bisDatum: string;
    /**
     * 
     * @type {ReportPdfResponseBodyBerichtDatenStrukturInfo}
     * @memberof ReportPdfResponseBodyBerichtDaten
     */
    strukturInfo: ReportPdfResponseBodyBerichtDatenStrukturInfo;
}

/**
 * Check if a given object implements the ReportPdfResponseBodyBerichtDaten interface.
 */
export function instanceOfReportPdfResponseBodyBerichtDaten(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vertragsNummer" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "vonDatum" in value;
    isInstance = isInstance && "bisDatum" in value;
    isInstance = isInstance && "strukturInfo" in value;

    return isInstance;
}

export function ReportPdfResponseBodyBerichtDatenFromJSON(json: any): ReportPdfResponseBodyBerichtDaten {
    return ReportPdfResponseBodyBerichtDatenFromJSONTyped(json, false);
}

export function ReportPdfResponseBodyBerichtDatenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPdfResponseBodyBerichtDaten {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertragsNummer': json['VertragsNummer'],
        'kreditorNummer': !exists(json, 'KreditorNummer') ? undefined : json['KreditorNummer'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'berichtsNummer': json['BerichtsNummer'],
        'abnahmeDatum': !exists(json, 'AbnahmeDatum') ? undefined : json['AbnahmeDatum'],
        'vonDatum': json['VonDatum'],
        'bisDatum': json['BisDatum'],
        'strukturInfo': ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSON(json['StrukturInfo']),
    };
}

export function ReportPdfResponseBodyBerichtDatenToJSON(value?: ReportPdfResponseBodyBerichtDaten | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'VertragsNummer': value.vertragsNummer,
        'KreditorNummer': value.kreditorNummer,
        'MaschinenGruppe': value.maschinenGruppe,
        'BerichtsNummer': value.berichtsNummer,
        'AbnahmeDatum': value.abnahmeDatum,
        'VonDatum': value.vonDatum,
        'BisDatum': value.bisDatum,
        'StrukturInfo': ReportPdfResponseBodyBerichtDatenStrukturInfoToJSON(value.strukturInfo),
    };
}

