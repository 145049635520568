/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutContractMachineGroupsRequest
 */
export interface PutContractMachineGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof PutContractMachineGroupsRequest
     */
    manPower: number;
}

/**
 * Check if a given object implements the PutContractMachineGroupsRequest interface.
 */
export function instanceOfPutContractMachineGroupsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "manPower" in value;

    return isInstance;
}

export function PutContractMachineGroupsRequestFromJSON(json: any): PutContractMachineGroupsRequest {
    return PutContractMachineGroupsRequestFromJSONTyped(json, false);
}

export function PutContractMachineGroupsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutContractMachineGroupsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manPower': json['manPower'],
    };
}

export function PutContractMachineGroupsRequestToJSON(value?: PutContractMachineGroupsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manPower': value.manPower,
    };
}

