import {
  ComponentInternalInstance,
  computed,
  getCurrentInstance,
  Ref,
} from "vue";
import { AuthenticationResult } from "@azure/msal-browser";
import { useBackendApi } from "./useBackendApi";
import { Page } from "@/authConfig";
import { getRolesFromAccessToken } from "@/utils/oauth/oauthHelper";

export interface OauthPermissions {
  roles: Ref<Set<string> | undefined>;

  hasAnyRole(...perms: string[]): boolean;
}

function init(instance: ComponentInternalInstance) {
  useBackendApi(Page.MAIN, instance); // We need to trigger the token fetch, otherwise nothing is going to happen

  const roles = computed(() => {
    return new Set(
      [instance.appContext.config.globalProperties.$backendApiAccessToken.value]
        .filter((a) => a)
        .flatMap((a) =>
          getRolesFromAccessToken((a as AuthenticationResult).accessToken),
        ),
    );
  });

  function hasAnyRole(...perms: string[]): boolean {
    return roles.value && perms.find((v) => roles.value.has(v)) !== undefined;
  }

  return {
    roles,
    hasAnyRole,
  };
}

export function useOauthPermissionsInfo(instance = getCurrentInstance()): OauthPermissions {
  if (!instance) {
    throw new Error("Must only be used inside setup() function of a component");
  }

  if (!instance.appContext.config.globalProperties.$oauthPermissionsInfo) {
    instance.appContext.config.globalProperties.$oauthPermissionsInfo =
      init(instance);
  }
  return instance.appContext.config.globalProperties.$oauthPermissionsInfo;
}
