import { DateTime, Duration } from "luxon";
import { AuthenticationResult } from "@azure/msal-browser";

export type AccessToken = AuthenticationResult;

export function isValidAccessToken(at?: AccessToken): boolean {
  return at != null && getAccessTokenRemainingDuration(at).toMillis() > 0;
}

export function isNearExpiry(at?: AccessToken): boolean {
  return !at || getAccessTokenEffectiveRemainingDuration(at).toMillis() < 0;
}

export function getAccessTokenEffectiveRemainingDuration(
  at: AccessToken,
): Duration {
  return getAccessTokenRemainingDuration(at).minus({ minutes: 10 });
}

export function getAccessTokenRemainingDuration(at: AccessToken): Duration {
  return DateTime.fromJSDate(at.expiresOn!).diffNow();
}
