/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgMaschinentyp
 */
export interface DbbgMaschinentyp {
    /**
     * 
     * @type {number}
     * @memberof DbbgMaschinentyp
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinentyp
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinentyp
     */
    komponente: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinentyp
     */
    gltigVon: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinentyp
     */
    gltigBis?: string;
}

/**
 * Check if a given object implements the DbbgMaschinentyp interface.
 */
export function instanceOfDbbgMaschinentyp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "komponente" in value;
    isInstance = isInstance && "gltigVon" in value;

    return isInstance;
}

export function DbbgMaschinentypFromJSON(json: any): DbbgMaschinentyp {
    return DbbgMaschinentypFromJSONTyped(json, false);
}

export function DbbgMaschinentypFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgMaschinentyp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'komponente': json['Komponente'],
        'gltigVon': json['GültigVon'],
        'gltigBis': !exists(json, 'GültigBis') ? undefined : json['GültigBis'],
    };
}

export function DbbgMaschinentypToJSON(value?: DbbgMaschinentyp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'Komponente': value.komponente,
        'GültigVon': value.gltigVon,
        'GültigBis': value.gltigBis,
    };
}

