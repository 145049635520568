/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgEinsatzEinreichenDaten
 */
export interface DbbgEinsatzEinreichenDaten {
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzEinreichenDaten
     */
    berichtPdf?: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzEinreichenDaten
     */
    berichtsNummer?: number;
}

/**
 * Check if a given object implements the DbbgEinsatzEinreichenDaten interface.
 */
export function instanceOfDbbgEinsatzEinreichenDaten(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DbbgEinsatzEinreichenDatenFromJSON(json: any): DbbgEinsatzEinreichenDaten {
    return DbbgEinsatzEinreichenDatenFromJSONTyped(json, false);
}

export function DbbgEinsatzEinreichenDatenFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzEinreichenDaten {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtPdf': !exists(json, 'BerichtPdf') ? undefined : json['BerichtPdf'],
        'berichtsNummer': !exists(json, 'BerichtsNummer') ? undefined : json['BerichtsNummer'],
    };
}

export function DbbgEinsatzEinreichenDatenToJSON(value?: DbbgEinsatzEinreichenDaten | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtPdf': value.berichtPdf,
        'BerichtsNummer': value.berichtsNummer,
    };
}

