import { App, Ref, ref } from "vue";

export const LanguagePlugin = {
  install: (app: App) => {
    app.config.globalProperties.$language = ref("de");
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $language: Ref<string>;
  }
}
