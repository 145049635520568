/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractPositionCategory
 */
export interface ContractPositionCategory {
    /**
     * 
     * @type {number}
     * @memberof ContractPositionCategory
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ContractPositionCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContractPositionCategory
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ContractPositionCategory
     */
    validTo?: string;
}

/**
 * Check if a given object implements the ContractPositionCategory interface.
 */
export function instanceOfContractPositionCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "validFrom" in value;

    return isInstance;
}

export function ContractPositionCategoryFromJSON(json: any): ContractPositionCategory {
    return ContractPositionCategoryFromJSONTyped(json, false);
}

export function ContractPositionCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractPositionCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'validFrom': json['validFrom'],
        'validTo': !exists(json, 'validTo') ? undefined : json['validTo'],
    };
}

export function ContractPositionCategoryToJSON(value?: ContractPositionCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'validFrom': value.validFrom,
        'validTo': value.validTo,
    };
}

