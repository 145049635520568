/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RechnungsdatenAorv } from './RechnungsdatenAorv';
import {
    RechnungsdatenAorvFromJSON,
    RechnungsdatenAorvFromJSONTyped,
    RechnungsdatenAorvToJSON,
} from './RechnungsdatenAorv';
import type { RechnungsdatenDbbg } from './RechnungsdatenDbbg';
import {
    RechnungsdatenDbbgFromJSON,
    RechnungsdatenDbbgFromJSONTyped,
    RechnungsdatenDbbgToJSON,
} from './RechnungsdatenDbbg';
import type { RechnungsdatenOebbArge } from './RechnungsdatenOebbArge';
import {
    RechnungsdatenOebbArgeFromJSON,
    RechnungsdatenOebbArgeFromJSONTyped,
    RechnungsdatenOebbArgeToJSON,
} from './RechnungsdatenOebbArge';

/**
 * 
 * @export
 * @interface RechnungsdatenSpeichernRequest
 */
export interface RechnungsdatenSpeichernRequest {
    /**
     * 
     * @type {RechnungsdatenOebbArge}
     * @memberof RechnungsdatenSpeichernRequest
     */
    rechnungsdatenOebbArge?: RechnungsdatenOebbArge;
    /**
     * 
     * @type {RechnungsdatenDbbg}
     * @memberof RechnungsdatenSpeichernRequest
     */
    rechnungsdatenDbbg?: RechnungsdatenDbbg;
    /**
     * 
     * @type {RechnungsdatenAorv}
     * @memberof RechnungsdatenSpeichernRequest
     */
    rechnungsdatenAorv?: RechnungsdatenAorv;
}

/**
 * Check if a given object implements the RechnungsdatenSpeichernRequest interface.
 */
export function instanceOfRechnungsdatenSpeichernRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechnungsdatenSpeichernRequestFromJSON(json: any): RechnungsdatenSpeichernRequest {
    return RechnungsdatenSpeichernRequestFromJSONTyped(json, false);
}

export function RechnungsdatenSpeichernRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechnungsdatenSpeichernRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rechnungsdatenOebbArge': !exists(json, 'RechnungsdatenOebbArge') ? undefined : RechnungsdatenOebbArgeFromJSON(json['RechnungsdatenOebbArge']),
        'rechnungsdatenDbbg': !exists(json, 'RechnungsdatenDbbg') ? undefined : RechnungsdatenDbbgFromJSON(json['RechnungsdatenDbbg']),
        'rechnungsdatenAorv': !exists(json, 'RechnungsdatenAorv') ? undefined : RechnungsdatenAorvFromJSON(json['RechnungsdatenAorv']),
    };
}

export function RechnungsdatenSpeichernRequestToJSON(value?: RechnungsdatenSpeichernRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RechnungsdatenOebbArge': RechnungsdatenOebbArgeToJSON(value.rechnungsdatenOebbArge),
        'RechnungsdatenDbbg': RechnungsdatenDbbgToJSON(value.rechnungsdatenDbbg),
        'RechnungsdatenAorv': RechnungsdatenAorvToJSON(value.rechnungsdatenAorv),
    };
}

