/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EintragTyp = {
    Geplant: 'GEPLANT',
    Durchgefuehrt: 'DURCHGEFUEHRT'
} as const;
export type EintragTyp = typeof EintragTyp[keyof typeof EintragTyp];


export function EintragTypFromJSON(json: any): EintragTyp {
    return EintragTypFromJSONTyped(json, false);
}

export function EintragTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintragTyp {
    return json as EintragTyp;
}

export function EintragTypToJSON(value?: EintragTyp | null): any {
    return value as any;
}

