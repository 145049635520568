/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BerichtsStatusElement,
  BerichtszustandGraph,
} from '../models/index';
import {
    BerichtsStatusElementFromJSON,
    BerichtsStatusElementToJSON,
    BerichtszustandGraphFromJSON,
    BerichtszustandGraphToJSON,
} from '../models/index';

export interface BerichtsStatusGraphAbholenFuerBerichtRequest {
    id: number;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Gibt die möglichen Status eines Berichts zurück.
     * Gibt die möglichen Status eines Berichts zurück.
     */
    async berichtsStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BerichtsStatusElement>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BerichtsStatusElementFromJSON));
    }

    /**
     * Gibt die möglichen Status eines Berichts zurück.
     * Gibt die möglichen Status eines Berichts zurück.
     */
    async berichtsStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BerichtsStatusElement>> {
        const response = await this.berichtsStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt alle möglichen Stationen zurück, die der Bericht durchlaufen kann. Die States werden über IDs referenziert, die nur innerhalb des Responses gültig sind. Ein Berichtsstatus kommt exakt einmal oder gar nicht vor. 
     * Gibt den Graph aller möglichen Berichts-Status eines Berichtes zurück.
     */
    async berichtsStatusGraphAbholenFuerBerichtRaw(requestParameters: BerichtsStatusGraphAbholenFuerBerichtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerichtszustandGraph>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berichtsStatusGraphAbholenFuerBericht.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/states/report/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerichtszustandGraphFromJSON(jsonValue));
    }

    /**
     * Gibt alle möglichen Stationen zurück, die der Bericht durchlaufen kann. Die States werden über IDs referenziert, die nur innerhalb des Responses gültig sind. Ein Berichtsstatus kommt exakt einmal oder gar nicht vor. 
     * Gibt den Graph aller möglichen Berichts-Status eines Berichtes zurück.
     */
    async berichtsStatusGraphAbholenFuerBericht(requestParameters: BerichtsStatusGraphAbholenFuerBerichtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerichtszustandGraph> {
        const response = await this.berichtsStatusGraphAbholenFuerBerichtRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
