/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen Connector
 * RVC Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserInfo,
} from '../models/index';
import {
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models/index';

/**
 * 
 */
export class MeApi extends runtime.BaseAPI {

    /**
     */
    async getCurrentUserInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/v1.0/me?$select=id,userPrincipalName,jobTitle,mail,mobilePhone,preferredLanguage,displayName,companyName,department`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async getCurrentUserInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo> {
        const response = await this.getCurrentUserInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentUserPhotoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/v1.0/me/photos/120x120/$value`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getCurrentUserPhoto(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getCurrentUserPhotoRaw(initOverrides);
        return await response.value();
    }

}
