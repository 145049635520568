/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BerichtsStatus = {
    PdfFehlt: 'PDF_FEHLT',
    PdfHochgeladen: 'PDF_HOCHGELADEN',
    InPruefung: 'IN_PRUEFUNG',
    PruefungFehler: 'PRUEFUNG_FEHLER',
    PruefungOk: 'PRUEFUNG_OK',
    ExternVerschickt: 'EXTERN_VERSCHICKT',
    ExternErhalten: 'EXTERN_ERHALTEN',
    AbgenommenNichtVerrechenbar: 'ABGENOMMEN_NICHT_VERRECHENBAR',
    AbgenommenVerrechenbar: 'ABGENOMMEN_VERRECHENBAR',
    Verrechnet: 'VERRECHNET',
    Abgeschlossen: 'ABGESCHLOSSEN'
} as const;
export type BerichtsStatus = typeof BerichtsStatus[keyof typeof BerichtsStatus];


export function BerichtsStatusFromJSON(json: any): BerichtsStatus {
    return BerichtsStatusFromJSONTyped(json, false);
}

export function BerichtsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtsStatus {
    return json as BerichtsStatus;
}

export function BerichtsStatusToJSON(value?: BerichtsStatus | null): any {
    return value as any;
}

