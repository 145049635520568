/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EintragTyp } from './EintragTyp';
import {
    EintragTypFromJSON,
    EintragTypFromJSONTyped,
    EintragTypToJSON,
} from './EintragTyp';
import type { ZuordnungTyp } from './ZuordnungTyp';
import {
    ZuordnungTypFromJSON,
    ZuordnungTypFromJSONTyped,
    ZuordnungTypToJSON,
} from './ZuordnungTyp';

/**
 * 
 * @export
 * @interface EintragZuordnung
 */
export interface EintragZuordnung {
    /**
     * 
     * @type {EintragTyp}
     * @memberof EintragZuordnung
     */
    eintragTyp: EintragTyp;
    /**
     * 
     * @type {string}
     * @memberof EintragZuordnung
     */
    einsatzart: string;
    /**
     * 
     * @type {string}
     * @memberof EintragZuordnung
     */
    iD: string;
    /**
     * 
     * @type {string}
     * @memberof EintragZuordnung
     */
    durchfuehrungVon: string;
    /**
     * 
     * @type {string}
     * @memberof EintragZuordnung
     */
    durchfuehrungBis: string;
    /**
     * 
     * @type {number}
     * @memberof EintragZuordnung
     */
    berichtsnummer?: number;
    /**
     * 
     * @type {string}
     * @memberof EintragZuordnung
     */
    einsatzort: string;
    /**
     * Gibt die zeitliche Überschneidung in Prozent (0 - 1) des Eintrags mit dem abgefragten Einsatz an.
     * @type {number}
     * @memberof EintragZuordnung
     */
    ueberschneidungInProzent: number;
    /**
     * Gibt den Grund einer automatischen Zuordnung an.
     * @type {string}
     * @memberof EintragZuordnung
     */
    grund?: string;
    /**
     * 
     * @type {ZuordnungTyp}
     * @memberof EintragZuordnung
     */
    berichtsZuordnung?: ZuordnungTyp;
}

/**
 * Check if a given object implements the EintragZuordnung interface.
 */
export function instanceOfEintragZuordnung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eintragTyp" in value;
    isInstance = isInstance && "einsatzart" in value;
    isInstance = isInstance && "iD" in value;
    isInstance = isInstance && "durchfuehrungVon" in value;
    isInstance = isInstance && "durchfuehrungBis" in value;
    isInstance = isInstance && "einsatzort" in value;
    isInstance = isInstance && "ueberschneidungInProzent" in value;

    return isInstance;
}

export function EintragZuordnungFromJSON(json: any): EintragZuordnung {
    return EintragZuordnungFromJSONTyped(json, false);
}

export function EintragZuordnungFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintragZuordnung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eintragTyp': EintragTypFromJSON(json['EintragTyp']),
        'einsatzart': json['Einsatzart'],
        'iD': json['ID'],
        'durchfuehrungVon': json['DurchfuehrungVon'],
        'durchfuehrungBis': json['DurchfuehrungBis'],
        'berichtsnummer': !exists(json, 'Berichtsnummer') ? undefined : json['Berichtsnummer'],
        'einsatzort': json['Einsatzort'],
        'ueberschneidungInProzent': json['UeberschneidungInProzent'],
        'grund': !exists(json, 'Grund') ? undefined : json['Grund'],
        'berichtsZuordnung': !exists(json, 'BerichtsZuordnung') ? undefined : ZuordnungTypFromJSON(json['BerichtsZuordnung']),
    };
}

export function EintragZuordnungToJSON(value?: EintragZuordnung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EintragTyp': EintragTypToJSON(value.eintragTyp),
        'Einsatzart': value.einsatzart,
        'ID': value.iD,
        'DurchfuehrungVon': value.durchfuehrungVon,
        'DurchfuehrungBis': value.durchfuehrungBis,
        'Berichtsnummer': value.berichtsnummer,
        'Einsatzort': value.einsatzort,
        'UeberschneidungInProzent': value.ueberschneidungInProzent,
        'Grund': value.grund,
        'BerichtsZuordnung': ZuordnungTypToJSON(value.berichtsZuordnung),
    };
}

