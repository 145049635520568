/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PdfPartMeta } from './PdfPartMeta';
import {
    PdfPartMetaFromJSON,
    PdfPartMetaFromJSONTyped,
    PdfPartMetaToJSON,
} from './PdfPartMeta';

/**
 * 
 * @export
 * @interface ReportPdfResponseBodyBerichtDatenStrukturInfo
 */
export interface ReportPdfResponseBodyBerichtDatenStrukturInfo {
    /**
     * 
     * @type {PdfPartMeta}
     * @memberof ReportPdfResponseBodyBerichtDatenStrukturInfo
     */
    abnahmeprotokoll?: PdfPartMeta;
    /**
     * 
     * @type {PdfPartMeta}
     * @memberof ReportPdfResponseBodyBerichtDatenStrukturInfo
     */
    rechnungsuebersicht?: PdfPartMeta;
    /**
     * 
     * @type {PdfPartMeta}
     * @memberof ReportPdfResponseBodyBerichtDatenStrukturInfo
     */
    ausmassblatt?: PdfPartMeta;
    /**
     * 
     * @type {PdfPartMeta}
     * @memberof ReportPdfResponseBodyBerichtDatenStrukturInfo
     */
    tagesbericht?: PdfPartMeta;
    /**
     * 
     * @type {PdfPartMeta}
     * @memberof ReportPdfResponseBodyBerichtDatenStrukturInfo
     */
    zusatz?: PdfPartMeta;
}

/**
 * Check if a given object implements the ReportPdfResponseBodyBerichtDatenStrukturInfo interface.
 */
export function instanceOfReportPdfResponseBodyBerichtDatenStrukturInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSON(json: any): ReportPdfResponseBodyBerichtDatenStrukturInfo {
    return ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSONTyped(json, false);
}

export function ReportPdfResponseBodyBerichtDatenStrukturInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPdfResponseBodyBerichtDatenStrukturInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abnahmeprotokoll': !exists(json, 'Abnahmeprotokoll') ? undefined : PdfPartMetaFromJSON(json['Abnahmeprotokoll']),
        'rechnungsuebersicht': !exists(json, 'Rechnungsuebersicht') ? undefined : PdfPartMetaFromJSON(json['Rechnungsuebersicht']),
        'ausmassblatt': !exists(json, 'Ausmassblatt') ? undefined : PdfPartMetaFromJSON(json['Ausmassblatt']),
        'tagesbericht': !exists(json, 'Tagesbericht') ? undefined : PdfPartMetaFromJSON(json['Tagesbericht']),
        'zusatz': !exists(json, 'Zusatz') ? undefined : PdfPartMetaFromJSON(json['Zusatz']),
    };
}

export function ReportPdfResponseBodyBerichtDatenStrukturInfoToJSON(value?: ReportPdfResponseBodyBerichtDatenStrukturInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Abnahmeprotokoll': PdfPartMetaToJSON(value.abnahmeprotokoll),
        'Rechnungsuebersicht': PdfPartMetaToJSON(value.rechnungsuebersicht),
        'Ausmassblatt': PdfPartMetaToJSON(value.ausmassblatt),
        'Tagesbericht': PdfPartMetaToJSON(value.tagesbericht),
        'Zusatz': PdfPartMetaToJSON(value.zusatz),
    };
}

