/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';

/**
 * 
 * @export
 * @interface StateTransition
 */
export interface StateTransition {
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof StateTransition
     */
    state: BerichtsStatus;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof StateTransition
     */
    successState?: BerichtsStatus;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof StateTransition
     */
    failedState?: BerichtsStatus;
}

/**
 * Check if a given object implements the StateTransition interface.
 */
export function instanceOfStateTransition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function StateTransitionFromJSON(json: any): StateTransition {
    return StateTransitionFromJSONTyped(json, false);
}

export function StateTransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateTransition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': BerichtsStatusFromJSON(json['state']),
        'successState': !exists(json, 'successState') ? undefined : BerichtsStatusFromJSON(json['successState']),
        'failedState': !exists(json, 'failedState') ? undefined : BerichtsStatusFromJSON(json['failedState']),
    };
}

export function StateTransitionToJSON(value?: StateTransition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': BerichtsStatusToJSON(value.state),
        'successState': BerichtsStatusToJSON(value.successState),
        'failedState': BerichtsStatusToJSON(value.failedState),
    };
}

