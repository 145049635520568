/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MachineGroup,
} from '../models/index';
import {
    MachineGroupFromJSON,
    MachineGroupToJSON,
} from '../models/index';

export interface BerichtEinreichenRequest {
    sicherungZip: string;
    berichtPdf: string;
    signatur: string;
    einsatzart?: BerichtEinreichenEinsatzartEnum;
    empfaengerExtern?: string;
    kommentar?: string;
}

export interface SapNummernAendernRequest {
    sicherungZip: string;
    signatur: string;
}

/**
 * 
 */
export class SubmitApi extends runtime.BaseAPI {

    /**
     * Reicht einen Bericht (RVC-Sicherung und PDF) zur Prüfung ein.
     */
    async berichtEinreichenRaw(requestParameters: BerichtEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sicherungZip === null || requestParameters.sicherungZip === undefined) {
            throw new runtime.RequiredError('sicherungZip','Required parameter requestParameters.sicherungZip was null or undefined when calling berichtEinreichen.');
        }

        if (requestParameters.berichtPdf === null || requestParameters.berichtPdf === undefined) {
            throw new runtime.RequiredError('berichtPdf','Required parameter requestParameters.berichtPdf was null or undefined when calling berichtEinreichen.');
        }

        if (requestParameters.signatur === null || requestParameters.signatur === undefined) {
            throw new runtime.RequiredError('signatur','Required parameter requestParameters.signatur was null or undefined when calling berichtEinreichen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.sicherungZip !== undefined) {
            formParams.append('SicherungZip', requestParameters.sicherungZip as any);
        }

        if (requestParameters.berichtPdf !== undefined) {
            formParams.append('BerichtPdf', requestParameters.berichtPdf as any);
        }

        if (requestParameters.signatur !== undefined) {
            formParams.append('Signatur', requestParameters.signatur as any);
        }

        if (requestParameters.einsatzart !== undefined) {
            formParams.append('Einsatzart', requestParameters.einsatzart as any);
        }

        if (requestParameters.empfaengerExtern !== undefined) {
            formParams.append('EmpfaengerExtern', requestParameters.empfaengerExtern as any);
        }

        if (requestParameters.kommentar !== undefined) {
            formParams.append('Kommentar', requestParameters.kommentar as any);
        }

        const response = await this.request({
            path: `/api/v1/reports/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reicht einen Bericht (RVC-Sicherung und PDF) zur Prüfung ein.
     */
    async berichtEinreichen(requestParameters: BerichtEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.berichtEinreichenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt eine Liste aller Maschinengruppen zurück.
     * Gibt eine Liste aller Maschinengruppen zurück.
     */
    async maschinengruppenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MachineGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/machinegroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineGroupFromJSON));
    }

    /**
     * Gibt eine Liste aller Maschinengruppen zurück.
     * Gibt eine Liste aller Maschinengruppen zurück.
     */
    async maschinengruppen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MachineGroup>> {
        const response = await this.maschinengruppenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ersetzt die zuletzt eingereichte Sicherung und speichert die SAP-Nummern als geändert.
     */
    async sapNummernAendernRaw(requestParameters: SapNummernAendernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sicherungZip === null || requestParameters.sicherungZip === undefined) {
            throw new runtime.RequiredError('sicherungZip','Required parameter requestParameters.sicherungZip was null or undefined when calling sapNummernAendern.');
        }

        if (requestParameters.signatur === null || requestParameters.signatur === undefined) {
            throw new runtime.RequiredError('signatur','Required parameter requestParameters.signatur was null or undefined when calling sapNummernAendern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.sicherungZip !== undefined) {
            formParams.append('SicherungZip', requestParameters.sicherungZip as any);
        }

        if (requestParameters.signatur !== undefined) {
            formParams.append('Signatur', requestParameters.signatur as any);
        }

        const response = await this.request({
            path: `/api/v1/reports/sapNumbers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ersetzt die zuletzt eingereichte Sicherung und speichert die SAP-Nummern als geändert.
     */
    async sapNummernAendern(requestParameters: SapNummernAendernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sapNummernAendernRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const BerichtEinreichenEinsatzartEnum = {
    Nl: 'NL',
    Erh: 'ERH'
} as const;
export type BerichtEinreichenEinsatzartEnum = typeof BerichtEinreichenEinsatzartEnum[keyof typeof BerichtEinreichenEinsatzartEnum];
