/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Company,
} from '../models/index';
import {
    CompanyFromJSON,
    CompanyToJSON,
} from '../models/index';

export interface CreateCompanyRequest {
    company: Company;
}

export interface DeleteCompanyRequest {
    companyId: number;
}

export interface GetCompanyRequest {
    companyId: number;
}

export interface PutCompanyRequest {
    companyId: number;
    company: Company;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Company>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Company>> {
        const response = await this.getCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putCompanyRaw(requestParameters: PutCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling putCompany.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling putCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async putCompany(requestParameters: PutCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.putCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
