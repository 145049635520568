/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BerichtOEBB
 */
export interface BerichtOEBB {
    /**
     * 
     * @type {string}
     * @memberof BerichtOEBB
     */
    region: string;
    /**
     * Regionalleitung (RL)
     * @type {string}
     * @memberof BerichtOEBB
     */
    regionalleitung: string;
    /**
     * 
     * @type {string}
     * @memberof BerichtOEBB
     */
    standort: string;
    /**
     * 
     * @type {string}
     * @memberof BerichtOEBB
     */
    einsatzartKategorie?: BerichtOEBBEinsatzartKategorieEnum;
    /**
     * 
     * @type {string}
     * @memberof BerichtOEBB
     */
    empfaenger?: string;
}


/**
 * @export
 */
export const BerichtOEBBEinsatzartKategorieEnum = {
    Nl: 'NL',
    Erh: 'ERH'
} as const;
export type BerichtOEBBEinsatzartKategorieEnum = typeof BerichtOEBBEinsatzartKategorieEnum[keyof typeof BerichtOEBBEinsatzartKategorieEnum];


/**
 * Check if a given object implements the BerichtOEBB interface.
 */
export function instanceOfBerichtOEBB(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "regionalleitung" in value;
    isInstance = isInstance && "standort" in value;

    return isInstance;
}

export function BerichtOEBBFromJSON(json: any): BerichtOEBB {
    return BerichtOEBBFromJSONTyped(json, false);
}

export function BerichtOEBBFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtOEBB {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['Region'],
        'regionalleitung': json['Regionalleitung'],
        'standort': json['Standort'],
        'einsatzartKategorie': !exists(json, 'EinsatzartKategorie') ? undefined : json['EinsatzartKategorie'],
        'empfaenger': !exists(json, 'Empfaenger') ? undefined : json['Empfaenger'],
    };
}

export function BerichtOEBBToJSON(value?: BerichtOEBB | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Region': value.region,
        'Regionalleitung': value.regionalleitung,
        'Standort': value.standort,
        'EinsatzartKategorie': value.einsatzartKategorie,
        'Empfaenger': value.empfaenger,
    };
}

