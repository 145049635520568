/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonMachineGroupReportsCheckWithState } from './JsonMachineGroupReportsCheckWithState';
import {
    JsonMachineGroupReportsCheckWithStateFromJSON,
    JsonMachineGroupReportsCheckWithStateFromJSONTyped,
    JsonMachineGroupReportsCheckWithStateToJSON,
} from './JsonMachineGroupReportsCheckWithState';
import type { SapAenderung } from './SapAenderung';
import {
    SapAenderungFromJSON,
    SapAenderungFromJSONTyped,
    SapAenderungToJSON,
} from './SapAenderung';

/**
 * 
 * @export
 * @interface SignedMachineGroupsReportsCheckWithState
 */
export interface SignedMachineGroupsReportsCheckWithState {
    /**
     * 
     * @type {Array<JsonMachineGroupReportsCheckWithState>}
     * @memberof SignedMachineGroupsReportsCheckWithState
     */
    ergebnis: Array<JsonMachineGroupReportsCheckWithState>;
    /**
     * Signatur für die hochgeladene ZIP-Datei. Muss im nächsten Eingabeschritt wieder angegeben werden.
     * @type {string}
     * @memberof SignedMachineGroupsReportsCheckWithState
     */
    signatur: string;
    /**
     * 
     * @type {Array<SapAenderung>}
     * @memberof SignedMachineGroupsReportsCheckWithState
     */
    sapAenderungen: Array<SapAenderung>;
}

/**
 * Check if a given object implements the SignedMachineGroupsReportsCheckWithState interface.
 */
export function instanceOfSignedMachineGroupsReportsCheckWithState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ergebnis" in value;
    isInstance = isInstance && "signatur" in value;
    isInstance = isInstance && "sapAenderungen" in value;

    return isInstance;
}

export function SignedMachineGroupsReportsCheckWithStateFromJSON(json: any): SignedMachineGroupsReportsCheckWithState {
    return SignedMachineGroupsReportsCheckWithStateFromJSONTyped(json, false);
}

export function SignedMachineGroupsReportsCheckWithStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignedMachineGroupsReportsCheckWithState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ergebnis': ((json['Ergebnis'] as Array<any>).map(JsonMachineGroupReportsCheckWithStateFromJSON)),
        'signatur': json['Signatur'],
        'sapAenderungen': ((json['SapAenderungen'] as Array<any>).map(SapAenderungFromJSON)),
    };
}

export function SignedMachineGroupsReportsCheckWithStateToJSON(value?: SignedMachineGroupsReportsCheckWithState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Ergebnis': ((value.ergebnis as Array<any>).map(JsonMachineGroupReportsCheckWithStateToJSON)),
        'Signatur': value.signatur,
        'SapAenderungen': ((value.sapAenderungen as Array<any>).map(SapAenderungToJSON)),
    };
}

