/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EinreichenKonfiguration } from './EinreichenKonfiguration';
import {
    EinreichenKonfigurationFromJSON,
    EinreichenKonfigurationFromJSONTyped,
    EinreichenKonfigurationToJSON,
} from './EinreichenKonfiguration';
import type { JsonReportCheckWithState } from './JsonReportCheckWithState';
import {
    JsonReportCheckWithStateFromJSON,
    JsonReportCheckWithStateFromJSONTyped,
    JsonReportCheckWithStateToJSON,
} from './JsonReportCheckWithState';

/**
 * 
 * @export
 * @interface JsonMachineGroupReportsCheckWithState
 */
export interface JsonMachineGroupReportsCheckWithState {
    /**
     * 
     * @type {number}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    vertragsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    vertragsName: string;
    /**
     * 
     * @type {string}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    firmenName: string;
    /**
     * 
     * @type {string}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {Array<JsonReportCheckWithState>}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    berichte: Array<JsonReportCheckWithState>;
    /**
     * 
     * @type {EinreichenKonfiguration}
     * @memberof JsonMachineGroupReportsCheckWithState
     */
    einreichenKonfiguration: EinreichenKonfiguration;
}

/**
 * Check if a given object implements the JsonMachineGroupReportsCheckWithState interface.
 */
export function instanceOfJsonMachineGroupReportsCheckWithState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vertragsNummer" in value;
    isInstance = isInstance && "vertragsName" in value;
    isInstance = isInstance && "firmenName" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berichte" in value;
    isInstance = isInstance && "einreichenKonfiguration" in value;

    return isInstance;
}

export function JsonMachineGroupReportsCheckWithStateFromJSON(json: any): JsonMachineGroupReportsCheckWithState {
    return JsonMachineGroupReportsCheckWithStateFromJSONTyped(json, false);
}

export function JsonMachineGroupReportsCheckWithStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonMachineGroupReportsCheckWithState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertragsNummer': json['VertragsNummer'],
        'vertragsName': json['VertragsName'],
        'firmenName': json['FirmenName'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'berichte': ((json['Berichte'] as Array<any>).map(JsonReportCheckWithStateFromJSON)),
        'einreichenKonfiguration': EinreichenKonfigurationFromJSON(json['EinreichenKonfiguration']),
    };
}

export function JsonMachineGroupReportsCheckWithStateToJSON(value?: JsonMachineGroupReportsCheckWithState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'VertragsNummer': value.vertragsNummer,
        'VertragsName': value.vertragsName,
        'FirmenName': value.firmenName,
        'MaschinenGruppe': value.maschinenGruppe,
        'Berichte': ((value.berichte as Array<any>).map(JsonReportCheckWithStateToJSON)),
        'EinreichenKonfiguration': EinreichenKonfigurationToJSON(value.einreichenKonfiguration),
    };
}

