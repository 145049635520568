/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Notiz,
} from '../models/index';
import {
    NotizFromJSON,
    NotizToJSON,
} from '../models/index';

export interface NotizHinzufuegenRequest {
    berichtsID: string;
    notiz: string;
}

export interface NotizLoeschenRequest {
    berichtsID: string;
    von: string;
    am: string;
}

export interface NotizObsoletSetzenRequest {
    berichtsID: string;
    von: string;
    am: string;
}

export interface NotizenLadenRequest {
    berichtsID: string;
}

export interface PersoenlicheNotizenLadenRequest {
    berichtsID: string;
}

export interface PersoenlicheNotizenSpeichernRequest {
    berichtsID: string;
    notizen: string;
}

/**
 * 
 */
export class NotesApi extends runtime.BaseAPI {

    /**
     * Fügt dem Bericht eine neue Notiz hinzu.
     */
    async notizHinzufuegenRaw(requestParameters: NotizHinzufuegenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notiz>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling notizHinzufuegen.');
        }

        if (requestParameters.notiz === null || requestParameters.notiz === undefined) {
            throw new runtime.RequiredError('notiz','Required parameter requestParameters.notiz was null or undefined when calling notizHinzufuegen.');
        }

        const queryParameters: any = {};

        if (requestParameters.notiz !== undefined) {
            queryParameters['Notiz'] = requestParameters.notiz;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/notes/{BerichtsID}`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotizFromJSON(jsonValue));
    }

    /**
     * Fügt dem Bericht eine neue Notiz hinzu.
     */
    async notizHinzufuegen(requestParameters: NotizHinzufuegenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notiz> {
        const response = await this.notizHinzufuegenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Markiert eine Notiz als gelöscht.
     */
    async notizLoeschenRaw(requestParameters: NotizLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notiz>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling notizLoeschen.');
        }

        if (requestParameters.von === null || requestParameters.von === undefined) {
            throw new runtime.RequiredError('von','Required parameter requestParameters.von was null or undefined when calling notizLoeschen.');
        }

        if (requestParameters.am === null || requestParameters.am === undefined) {
            throw new runtime.RequiredError('am','Required parameter requestParameters.am was null or undefined when calling notizLoeschen.');
        }

        const queryParameters: any = {};

        if (requestParameters.von !== undefined) {
            queryParameters['Von'] = requestParameters.von;
        }

        if (requestParameters.am !== undefined) {
            queryParameters['Am'] = requestParameters.am;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/notes/{BerichtsID}/delete`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotizFromJSON(jsonValue));
    }

    /**
     * Markiert eine Notiz als gelöscht.
     */
    async notizLoeschen(requestParameters: NotizLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notiz> {
        const response = await this.notizLoeschenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Markiert die Notiz eines Berichts als veraltet bzw. nicht mehr gültig.
     */
    async notizObsoletSetzenRaw(requestParameters: NotizObsoletSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notiz>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling notizObsoletSetzen.');
        }

        if (requestParameters.von === null || requestParameters.von === undefined) {
            throw new runtime.RequiredError('von','Required parameter requestParameters.von was null or undefined when calling notizObsoletSetzen.');
        }

        if (requestParameters.am === null || requestParameters.am === undefined) {
            throw new runtime.RequiredError('am','Required parameter requestParameters.am was null or undefined when calling notizObsoletSetzen.');
        }

        const queryParameters: any = {};

        if (requestParameters.von !== undefined) {
            queryParameters['Von'] = requestParameters.von;
        }

        if (requestParameters.am !== undefined) {
            queryParameters['Am'] = requestParameters.am;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/notes/{BerichtsID}/obsolete`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotizFromJSON(jsonValue));
    }

    /**
     * Markiert die Notiz eines Berichts als veraltet bzw. nicht mehr gültig.
     */
    async notizObsoletSetzen(requestParameters: NotizObsoletSetzenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notiz> {
        const response = await this.notizObsoletSetzenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lädt Notizen eines Berichts.
     */
    async notizenLadenRaw(requestParameters: NotizenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notiz>>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling notizenLaden.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/notes/{BerichtsID}`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotizFromJSON));
    }

    /**
     * Lädt Notizen eines Berichts.
     */
    async notizenLaden(requestParameters: NotizenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notiz>> {
        const response = await this.notizenLadenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lädt eine persönliche (von einem selbst erstellt) Notiz zu einem Bericht.
     * Persönliche Notizen zu einem Eintrag herunterladen.
     */
    async persoenlicheNotizenLadenRaw(requestParameters: PersoenlicheNotizenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling persoenlicheNotizenLaden.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/personalnotes/{BerichtsID}`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt eine persönliche (von einem selbst erstellt) Notiz zu einem Bericht.
     * Persönliche Notizen zu einem Eintrag herunterladen.
     */
    async persoenlicheNotizenLaden(requestParameters: PersoenlicheNotizenLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.persoenlicheNotizenLadenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Speichert die persönlichen, nur für sich selbst sichtbaren, Notizen zu einem Eintrag in der Berichtsliste.
     * Persönliche Notizen zu einem Eintrag speichern.
     */
    async persoenlicheNotizenSpeichernRaw(requestParameters: PersoenlicheNotizenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.berichtsID === null || requestParameters.berichtsID === undefined) {
            throw new runtime.RequiredError('berichtsID','Required parameter requestParameters.berichtsID was null or undefined when calling persoenlicheNotizenSpeichern.');
        }

        if (requestParameters.notizen === null || requestParameters.notizen === undefined) {
            throw new runtime.RequiredError('notizen','Required parameter requestParameters.notizen was null or undefined when calling persoenlicheNotizenSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.notizen !== undefined) {
            formParams.append('Notizen', requestParameters.notizen as any);
        }

        const response = await this.request({
            path: `/api/v1/personalnotes/{BerichtsID}`.replace(`{${"BerichtsID"}}`, encodeURIComponent(String(requestParameters.berichtsID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert die persönlichen, nur für sich selbst sichtbaren, Notizen zu einem Eintrag in der Berichtsliste.
     * Persönliche Notizen zu einem Eintrag speichern.
     */
    async persoenlicheNotizenSpeichern(requestParameters: PersoenlicheNotizenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.persoenlicheNotizenSpeichernRaw(requestParameters, initOverrides);
    }

}
