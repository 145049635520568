/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReportType = {
    Oebb: 'OEBB',
    Aorv: 'AORV',
    Dbbg: 'DBBG'
} as const;
export type ReportType = typeof ReportType[keyof typeof ReportType];


export function ReportTypeFromJSON(json: any): ReportType {
    return ReportTypeFromJSONTyped(json, false);
}

export function ReportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportType {
    return json as ReportType;
}

export function ReportTypeToJSON(value?: ReportType | null): any {
    return value as any;
}

