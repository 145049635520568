/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgRechnungsPositionDefinitionZuweisung
 */
export interface DbbgRechnungsPositionDefinitionZuweisung {
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinitionZuweisung
     */
    positionId: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinitionZuweisung
     */
    maschineId: number;
}

/**
 * Check if a given object implements the DbbgRechnungsPositionDefinitionZuweisung interface.
 */
export function instanceOfDbbgRechnungsPositionDefinitionZuweisung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "positionId" in value;
    isInstance = isInstance && "maschineId" in value;

    return isInstance;
}

export function DbbgRechnungsPositionDefinitionZuweisungFromJSON(json: any): DbbgRechnungsPositionDefinitionZuweisung {
    return DbbgRechnungsPositionDefinitionZuweisungFromJSONTyped(json, false);
}

export function DbbgRechnungsPositionDefinitionZuweisungFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgRechnungsPositionDefinitionZuweisung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionId': json['PositionId'],
        'maschineId': json['MaschineId'],
    };
}

export function DbbgRechnungsPositionDefinitionZuweisungToJSON(value?: DbbgRechnungsPositionDefinitionZuweisung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PositionId': value.positionId,
        'MaschineId': value.maschineId,
    };
}

