type ExistenceChecker<T> = (v: T | null | undefined) => boolean;

export class ImprovedMap<K, V> extends Map<K, V> {
  /**
   * Used for checking id something was in the map or not. Used e.g. in computeIfAbsent to tell if the value must be computed or not.
   */
  private readonly existenceChecker: ExistenceChecker<V>;

  constructor(existenceChecker: ExistenceChecker<V> = (v) => !!v) {
    super();
    this.existenceChecker = existenceChecker;
  }

  public computeIfAbsent(key: K, mapper: (key: K) => V): V {
    const got = this.get(key);
    if (this.existenceChecker(got)) {
      return got!;
    } else {
      const computed = mapper(key);
      this.set(key, computed);
      return computed;
    }
  }
}
