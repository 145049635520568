/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EreignisTyp } from './EreignisTyp';
import {
    EreignisTypFromJSON,
    EreignisTypFromJSONTyped,
    EreignisTypToJSON,
} from './EreignisTyp';

/**
 * 
 * @export
 * @interface Ereignis
 */
export interface Ereignis {
    /**
     * 
     * @type {EreignisTyp}
     * @memberof Ereignis
     */
    typ: EreignisTyp;
    /**
     * 
     * @type {string}
     * @memberof Ereignis
     */
    zeitpunkt: string;
    /**
     * 
     * @type {string}
     * @memberof Ereignis
     */
    benutzerId: string;
    /**
     * 
     * @type {string}
     * @memberof Ereignis
     */
    kommentar?: string;
    /**
     * True gibt an, dass das Ereignis nicht mehr gültig ist, da es durch Zurücksetzen des Berichts einen veralteten Status beschreibt.
     * @type {boolean}
     * @memberof Ereignis
     */
    ungueltigWeilZurueckgesetzt: boolean;
}

/**
 * Check if a given object implements the Ereignis interface.
 */
export function instanceOfEreignis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "zeitpunkt" in value;
    isInstance = isInstance && "benutzerId" in value;
    isInstance = isInstance && "ungueltigWeilZurueckgesetzt" in value;

    return isInstance;
}

export function EreignisFromJSON(json: any): Ereignis {
    return EreignisFromJSONTyped(json, false);
}

export function EreignisFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ereignis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typ': EreignisTypFromJSON(json['Typ']),
        'zeitpunkt': json['Zeitpunkt'],
        'benutzerId': json['BenutzerId'],
        'kommentar': !exists(json, 'Kommentar') ? undefined : json['Kommentar'],
        'ungueltigWeilZurueckgesetzt': json['UngueltigWeilZurueckgesetzt'],
    };
}

export function EreignisToJSON(value?: Ereignis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Typ': EreignisTypToJSON(value.typ),
        'Zeitpunkt': value.zeitpunkt,
        'BenutzerId': value.benutzerId,
        'Kommentar': value.kommentar,
        'UngueltigWeilZurueckgesetzt': value.ungueltigWeilZurueckgesetzt,
    };
}

