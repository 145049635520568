/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SapAenderung
 */
export interface SapAenderung {
    /**
     * 
     * @type {number}
     * @memberof SapAenderung
     */
    vertragsNummer: number;
    /**
     * 
     * @type {number}
     * @memberof SapAenderung
     */
    jahr: number;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {number}
     * @memberof SapAenderung
     */
    berichtsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    tagesberichtVon: string;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    tagesberichtBis: string;
    /**
     * 
     * @type {number}
     * @memberof SapAenderung
     */
    abschnittsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    sapNummerUpload: string;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    sapNummerStorage: string;
    /**
     * 
     * @type {string}
     * @memberof SapAenderung
     */
    sapNummerSoll: string;
}

/**
 * Check if a given object implements the SapAenderung interface.
 */
export function instanceOfSapAenderung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vertragsNummer" in value;
    isInstance = isInstance && "jahr" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "tagesberichtVon" in value;
    isInstance = isInstance && "tagesberichtBis" in value;
    isInstance = isInstance && "abschnittsNummer" in value;
    isInstance = isInstance && "sapNummerUpload" in value;
    isInstance = isInstance && "sapNummerStorage" in value;
    isInstance = isInstance && "sapNummerSoll" in value;

    return isInstance;
}

export function SapAenderungFromJSON(json: any): SapAenderung {
    return SapAenderungFromJSONTyped(json, false);
}

export function SapAenderungFromJSONTyped(json: any, ignoreDiscriminator: boolean): SapAenderung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertragsNummer': json['VertragsNummer'],
        'jahr': json['Jahr'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'berichtsNummer': json['BerichtsNummer'],
        'tagesberichtVon': json['TagesberichtVon'],
        'tagesberichtBis': json['TagesberichtBis'],
        'abschnittsNummer': json['AbschnittsNummer'],
        'sapNummerUpload': json['SapNummerUpload'],
        'sapNummerStorage': json['SapNummerStorage'],
        'sapNummerSoll': json['SapNummerSoll'],
    };
}

export function SapAenderungToJSON(value?: SapAenderung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'VertragsNummer': value.vertragsNummer,
        'Jahr': value.jahr,
        'MaschinenGruppe': value.maschinenGruppe,
        'BerichtsNummer': value.berichtsNummer,
        'TagesberichtVon': value.tagesberichtVon,
        'TagesberichtBis': value.tagesberichtBis,
        'AbschnittsNummer': value.abschnittsNummer,
        'SapNummerUpload': value.sapNummerUpload,
        'SapNummerStorage': value.sapNummerStorage,
        'SapNummerSoll': value.sapNummerSoll,
    };
}

