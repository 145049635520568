/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EigenschaftsOption = {
    Forbidden: 'FORBIDDEN',
    Allowed: 'ALLOWED',
    Required: 'REQUIRED'
} as const;
export type EigenschaftsOption = typeof EigenschaftsOption[keyof typeof EigenschaftsOption];


export function EigenschaftsOptionFromJSON(json: any): EigenschaftsOption {
    return EigenschaftsOptionFromJSONTyped(json, false);
}

export function EigenschaftsOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EigenschaftsOption {
    return json as EigenschaftsOption;
}

export function EigenschaftsOptionToJSON(value?: EigenschaftsOption | null): any {
    return value as any;
}

