/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Location,
} from '../models/index';
import {
    LocationFromJSON,
    LocationToJSON,
} from '../models/index';

export interface CreateLocationRequest {
    sectionId: number;
    location: Location;
}

export interface DeleteLocationRequest {
    locationId: number;
}

export interface GetCompanyLocationsRequest {
    companyId: number;
}

export interface GetLocationRequest {
    locationId: number;
}

export interface GetSectionLocationsRequest {
    sectionId: number;
}

export interface PutLocationRequest {
    location: Location;
}

/**
 * 
 */
export class LocationsApi extends runtime.BaseAPI {

    /**
     */
    async createLocationRaw(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling createLocation.');
        }

        if (requestParameters.location === null || requestParameters.location === undefined) {
            throw new runtime.RequiredError('location','Required parameter requestParameters.location was null or undefined when calling createLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}/locations`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationToJSON(requestParameters.location),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createLocation(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLocationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/locations/{locationId}`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLocation(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLocationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCompanyLocationsRaw(requestParameters: GetCompanyLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}/locations`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     */
    async getCompanyLocations(requestParameters: GetCompanyLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Location>> {
        const response = await this.getCompanyLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocationRaw(requestParameters: GetLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/locations/{locationId}`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     */
    async getLocation(requestParameters: GetLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Location> {
        const response = await this.getLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSectionLocationsRaw(requestParameters: GetSectionLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling getSectionLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}/locations`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     */
    async getSectionLocations(requestParameters: GetSectionLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Location>> {
        const response = await this.getSectionLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putLocationRaw(requestParameters: PutLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.location === null || requestParameters.location === undefined) {
            throw new runtime.RequiredError('location','Required parameter requestParameters.location was null or undefined when calling putLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/locations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationToJSON(requestParameters.location),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     */
    async putLocation(requestParameters: PutLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Location> {
        const response = await this.putLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
