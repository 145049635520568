import { Environment, POSSIBLE_ENVIRONMENTS } from "../environment";

export function getRolesFromAccessToken(accessToken: string): string[] {
  const decodedToken = parseJwt(accessToken);
  return decodedToken.roles || [];
}

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function hasAllScopes(
  scopesAssigned: string[],
  ...scopesToCheck: string[]
): boolean {
  const missing = new Set(scopesToCheck);
  scopesAssigned.forEach((r) => missing.delete(r));
  return missing.size === 0;
}

export function parseClientIds(
  envValue: any,
  devValues: { [key in Environment]?: string },
): { [key in Environment]: string } {
  if (!envValue) {
    // If no ClientId-object is provided ...
    if (__ENV_MODE__ === "development") {
      // and we are in a local dev env ...
      // then we silently take the hard-coded values.
      return devValues as { [key in Environment]: string };
    } else {
      // ... otherwise we are not developing and the ClientIds MUST be provided!
      throw new Error(
        "Provide CLIENT_IDS env var! E.g. CLIENT_IDS=03015cea-dc4b-429f-9a9b-327f5a573cb5",
      );
    }
  }

  try {
    // TODO Ideally, check should be moved to the point when everything is compiled
    // so it can fail directly, not here at runtime.

    // We try to read the env string as a JSON object containing the ClientIds for all environments...
    const external = JSON.parse(envValue);

    // Checks that each environment has a ClientId defined
    POSSIBLE_ENVIRONMENTS.forEach((e) => {
      if (external[e] === undefined) {
        throw new Error(
          `Client ID for ${e} undefined! All possible keys: ${[
            ...POSSIBLE_ENVIRONMENTS,
          ].join(", ")}`,
        );
      }
    });

    return external;
  } catch (e) {
    console.warn("Error parsing client id values! Taking it as single value!");
    return {
      [Environment.DEVELOPMENT]: envValue,
      [Environment.TEST]: envValue,
      [Environment.STAGING]: envValue,
      [Environment.PRODUCTION]: envValue,
    };
  }
}
