export function doWhenOnline(f: () => void | Promise<void>): {
  promise: Promise<void>;
  cancel: () => void;
} {
  if (navigator.onLine) {
    return {
      promise: Promise.resolve(f()),
      cancel: () => {},
    };
  } else {
    let cancel: () => void | undefined;
    return {
      promise: new Promise<void>((resolve, reject) => {
        const onlineListener = (e: Event) => {
          window.removeEventListener("online", onlineListener);
          Promise.resolve(f)
            .then(() => resolve())
            .catch((e) => reject(e));
        };
        window.addEventListener("online", onlineListener);
        cancel = () => window.removeEventListener("online", onlineListener);
      }),
      cancel: () => cancel?.(),
    };
  }
}
