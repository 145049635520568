/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Ereignis } from './Ereignis';
import {
    EreignisFromJSON,
    EreignisFromJSONTyped,
    EreignisToJSON,
} from './Ereignis';

/**
 * 
 * @export
 * @interface SapNummerStatus
 */
export interface SapNummerStatus {
    /**
     * 
     * @type {string}
     * @memberof SapNummerStatus
     */
    berichtsID: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerStatus
     */
    erstellt: string;
    /**
     * 
     * @type {number}
     * @memberof SapNummerStatus
     */
    berichtsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof SapNummerStatus
     */
    tagesberichtVon: string;
    /**
     * 
     * @type {string}
     * @memberof SapNummerStatus
     */
    tagesberichtBis: string;
    /**
     * 
     * @type {number}
     * @memberof SapNummerStatus
     */
    abschnittsNummer: number;
    /**
     * SAP-Nummer in Sicherung
     * @type {string}
     * @memberof SapNummerStatus
     */
    sapNummerIst: string;
    /**
     * SAP-Nummer zuvor (aus Datenbank)
     * @type {string}
     * @memberof SapNummerStatus
     */
    sapNummerAlt?: string;
    /**
     * SAP-Nummer angefordert (aus Datenbank)
     * @type {string}
     * @memberof SapNummerStatus
     */
    sapNummerSoll: string;
    /**
     * 
     * @type {Ereignis}
     * @memberof SapNummerStatus
     */
    angefordert?: Ereignis;
    /**
     * 
     * @type {Ereignis}
     * @memberof SapNummerStatus
     */
    geaendert?: Ereignis;
}

/**
 * Check if a given object implements the SapNummerStatus interface.
 */
export function instanceOfSapNummerStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtsID" in value;
    isInstance = isInstance && "erstellt" in value;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "tagesberichtVon" in value;
    isInstance = isInstance && "tagesberichtBis" in value;
    isInstance = isInstance && "abschnittsNummer" in value;
    isInstance = isInstance && "sapNummerIst" in value;
    isInstance = isInstance && "sapNummerSoll" in value;

    return isInstance;
}

export function SapNummerStatusFromJSON(json: any): SapNummerStatus {
    return SapNummerStatusFromJSONTyped(json, false);
}

export function SapNummerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SapNummerStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtsID': json['BerichtsID'],
        'erstellt': json['Erstellt'],
        'berichtsNummer': json['BerichtsNummer'],
        'tagesberichtVon': json['TagesberichtVon'],
        'tagesberichtBis': json['TagesberichtBis'],
        'abschnittsNummer': json['AbschnittsNummer'],
        'sapNummerIst': json['SapNummerIst'],
        'sapNummerAlt': !exists(json, 'SapNummerAlt') ? undefined : json['SapNummerAlt'],
        'sapNummerSoll': json['SapNummerSoll'],
        'angefordert': !exists(json, 'Angefordert') ? undefined : EreignisFromJSON(json['Angefordert']),
        'geaendert': !exists(json, 'Geaendert') ? undefined : EreignisFromJSON(json['Geaendert']),
    };
}

export function SapNummerStatusToJSON(value?: SapNummerStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtsID': value.berichtsID,
        'Erstellt': value.erstellt,
        'BerichtsNummer': value.berichtsNummer,
        'TagesberichtVon': value.tagesberichtVon,
        'TagesberichtBis': value.tagesberichtBis,
        'AbschnittsNummer': value.abschnittsNummer,
        'SapNummerIst': value.sapNummerIst,
        'SapNummerAlt': value.sapNummerAlt,
        'SapNummerSoll': value.sapNummerSoll,
        'Angefordert': EreignisToJSON(value.angefordert),
        'Geaendert': EreignisToJSON(value.geaendert),
    };
}

