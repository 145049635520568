/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EintragAbnahme
 */
export interface EintragAbnahme {
    /**
     * Abnahmefirst in Tagen basierend auf der Einsatzart.
     * @type {number}
     * @memberof EintragAbnahme
     */
    abnahmeFrist?: number;
    /**
     * 
     * @type {string}
     * @memberof EintragAbnahme
     */
    abnahmeFaelligkeit?: string;
    /**
     * 
     * @type {string}
     * @memberof EintragAbnahme
     */
    abnahmeDatum?: string;
}

/**
 * Check if a given object implements the EintragAbnahme interface.
 */
export function instanceOfEintragAbnahme(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EintragAbnahmeFromJSON(json: any): EintragAbnahme {
    return EintragAbnahmeFromJSONTyped(json, false);
}

export function EintragAbnahmeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintragAbnahme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abnahmeFrist': !exists(json, 'AbnahmeFrist') ? undefined : json['AbnahmeFrist'],
        'abnahmeFaelligkeit': !exists(json, 'AbnahmeFaelligkeit') ? undefined : json['AbnahmeFaelligkeit'],
        'abnahmeDatum': !exists(json, 'AbnahmeDatum') ? undefined : json['AbnahmeDatum'],
    };
}

export function EintragAbnahmeToJSON(value?: EintragAbnahme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AbnahmeFrist': value.abnahmeFrist,
        'AbnahmeFaelligkeit': value.abnahmeFaelligkeit,
        'AbnahmeDatum': value.abnahmeDatum,
    };
}

