import {
  ApiFehler,
  ApiFehlerMeldungParameter,
  ApiFehlerMeldungParameterTypeEnum,
} from "@/generated/submit";
import { DateTime } from "luxon";

function replaceStringWithArrayParameters(
  input: string,
  parameters: ApiFehlerMeldungParameter[],
) {
  return input.replace(/{(\d+)}/g, function (match, index) {
    return typeof parameters[index] !== "undefined"
      ? formatParameter(parameters[index])
      : match;
  });
}

function formatParameter(p: ApiFehlerMeldungParameter) {
  switch (p.type!) {
    case ApiFehlerMeldungParameterTypeEnum.Date:
      return (p.value! as Date).toLocaleDateString("de");
    case ApiFehlerMeldungParameterTypeEnum.DateTime:
      return DateTime.fromISO(p.value!).toLocaleString(DateTime.DATETIME_SHORT);
    case ApiFehlerMeldungParameterTypeEnum.Decimal:
      return (p.value! as number).toLocaleString("de");
    case ApiFehlerMeldungParameterTypeEnum.Integer:
      return (p.value! as number).toLocaleString("de");
    case ApiFehlerMeldungParameterTypeEnum.String:
      return p.value!;
  }
}

export class ApiError extends Error {
  override name: "ApiError" = "ApiError";
  localizedMessage: string;

  constructor(err: ApiFehler) {
    super(replaceStringWithArrayParameters(err.message, err.parameters));
    this.localizedMessage = replaceStringWithArrayParameters(
      err.localizedMessage,
      err.parameters,
    );
  }
}
