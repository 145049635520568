/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface UnterschriftHochladenRequest {
    unterschriftsbild: string;
}

/**
 * 
 */
export class IdentityApi extends runtime.BaseAPI {

    /**
     * Speichert ein Bild (nur PNG-Format) der Unterschrift des aktuell angemeldeten Benutzers.
     */
    async unterschriftHochladenRaw(requestParameters: UnterschriftHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unterschriftsbild === null || requestParameters.unterschriftsbild === undefined) {
            throw new runtime.RequiredError('unterschriftsbild','Required parameter requestParameters.unterschriftsbild was null or undefined when calling unterschriftHochladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.unterschriftsbild !== undefined) {
            formParams.append('Unterschriftsbild', requestParameters.unterschriftsbild as any);
        }

        const response = await this.request({
            path: `/api/v1/signature`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Speichert ein Bild (nur PNG-Format) der Unterschrift des aktuell angemeldeten Benutzers.
     */
    async unterschriftHochladen(requestParameters: UnterschriftHochladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unterschriftHochladenRaw(requestParameters, initOverrides);
    }

    /**
     * Lädt das Unterschriftsbild des aktuell angemeldeten Benutzers.
     */
    async unterschriftLadenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/signature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt das Unterschriftsbild des aktuell angemeldeten Benutzers.
     */
    async unterschriftLaden(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.unterschriftLadenRaw(initOverrides);
        return await response.value();
    }

}
