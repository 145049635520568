/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgEinsatzOperationTyp
 */
export interface DbbgEinsatzOperationTyp {
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzOperationTyp
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzOperationTyp
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbbgEinsatzOperationTyp
     */
    istVerrechenbar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbbgEinsatzOperationTyp
     */
    istPause: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbbgEinsatzOperationTyp
     */
    istAusgefallen: boolean;
}

/**
 * Check if a given object implements the DbbgEinsatzOperationTyp interface.
 */
export function instanceOfDbbgEinsatzOperationTyp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "istPause" in value;
    isInstance = isInstance && "istAusgefallen" in value;

    return isInstance;
}

export function DbbgEinsatzOperationTypFromJSON(json: any): DbbgEinsatzOperationTyp {
    return DbbgEinsatzOperationTypFromJSONTyped(json, false);
}

export function DbbgEinsatzOperationTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzOperationTyp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'istVerrechenbar': !exists(json, 'IstVerrechenbar') ? undefined : json['IstVerrechenbar'],
        'istPause': json['IstPause'],
        'istAusgefallen': json['IstAusgefallen'],
    };
}

export function DbbgEinsatzOperationTypToJSON(value?: DbbgEinsatzOperationTyp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Name': value.name,
        'IstVerrechenbar': value.istVerrechenbar,
        'IstPause': value.istPause,
        'IstAusgefallen': value.istAusgefallen,
    };
}

