/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Rechnungsposition
 */
export interface Rechnungsposition {
    /**
     * 
     * @type {number}
     * @memberof Rechnungsposition
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Rechnungsposition
     */
    positionsNummer: string;
    /**
     * 
     * @type {string}
     * @memberof Rechnungsposition
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Rechnungsposition
     */
    einheit: string;
    /**
     * 
     * @type {number}
     * @memberof Rechnungsposition
     */
    menge: number;
    /**
     * 
     * @type {number}
     * @memberof Rechnungsposition
     */
    einheitspreis: number;
    /**
     * 
     * @type {number}
     * @memberof Rechnungsposition
     */
    gesamtpreis: number;
}

/**
 * Check if a given object implements the Rechnungsposition interface.
 */
export function instanceOfRechnungsposition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "positionsNummer" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "einheit" in value;
    isInstance = isInstance && "menge" in value;
    isInstance = isInstance && "einheitspreis" in value;
    isInstance = isInstance && "gesamtpreis" in value;

    return isInstance;
}

export function RechnungspositionFromJSON(json: any): Rechnungsposition {
    return RechnungspositionFromJSONTyped(json, false);
}

export function RechnungspositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rechnungsposition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'positionsNummer': json['PositionsNummer'],
        'text': json['Text'],
        'einheit': json['Einheit'],
        'menge': json['Menge'],
        'einheitspreis': json['Einheitspreis'],
        'gesamtpreis': json['Gesamtpreis'],
    };
}

export function RechnungspositionToJSON(value?: Rechnungsposition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'PositionsNummer': value.positionsNummer,
        'Text': value.text,
        'Einheit': value.einheit,
        'Menge': value.menge,
        'Einheitspreis': value.einheitspreis,
        'Gesamtpreis': value.gesamtpreis,
    };
}

