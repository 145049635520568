import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  ComponentInternalInstance,
  getCurrentInstance,
  Ref,
} from "vue";

export type MsalContext = {
  instance: PublicClientApplication;
  accounts: Ref<AccountInfo[]>;
  interactionStatus: Ref<InteractionStatus>;
  addListener: (key: any, callback: () => void) => void;
  removeListener: (key: any) => void;
};

export function useMsal(
  currentInstance: ComponentInternalInstance | null = getCurrentInstance(),
): MsalContext {
  if (!currentInstance) {
    throw "useMsal() cannot be called outside the setup() function of a component";
  }
  const {
    instance,
    accounts,
    interactionStatus,
    addListener,
    removeListener,
  } = currentInstance.appContext.config.globalProperties.$msal as MsalContext;

  if (!instance || !accounts.value || !interactionStatus.value) {
    throw "Please install the msalPlugin";
  }

  if (interactionStatus.value === InteractionStatus.Startup) {
    instance
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        console.debug("useMsal::handleRedirect: " + (response ? response.accessToken : "null"));
      })
      .catch(() => {
        // Errors should be handled by listening to the LOGIN_FAILURE event
        console.debug("useMsal: login failed");
        return;
      });
  }

  return {
    instance,
    accounts,
    interactionStatus,
    addListener,
    removeListener,
  };
}
