/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Contract,
  CreateContractRequest,
  GetContractMachineGroups200ResponseInner,
  PutContractMachineGroupsRequest,
} from '../models/index';
import {
    ContractFromJSON,
    ContractToJSON,
    CreateContractRequestFromJSON,
    CreateContractRequestToJSON,
    GetContractMachineGroups200ResponseInnerFromJSON,
    GetContractMachineGroups200ResponseInnerToJSON,
    PutContractMachineGroupsRequestFromJSON,
    PutContractMachineGroupsRequestToJSON,
} from '../models/index';

export interface AngebotsVorlageHerunterladenRequest {
    contractId: number;
}

export interface CreateContractOperationRequest {
    createContractRequest: CreateContractRequest;
}

export interface DeleteContractMachineGroupsRequest {
    contractId: number;
    machineGroupId: string;
}

export interface GetCompanyContractsRequest {
    companyId: number;
}

export interface GetContractRequest {
    contractId: number;
}

export interface GetContractMachineGroupsRequest {
    contractId: number;
}

export interface GetContractsRequest {
    filter?: string;
}

export interface PutContractRequest {
    contractId: number;
    contract: Contract;
}

export interface PutContractMachineGroupsOperationRequest {
    contractId: number;
    machineGroupId: string;
    putContractMachineGroupsRequest: PutContractMachineGroupsRequest;
}

/**
 * 
 */
export class ContractsApi extends runtime.BaseAPI {

    /**
     * Lädt die mit Preisen vorbefüllte Angebotsvorlage herunter.
     */
    async angebotsVorlageHerunterladenRaw(requestParameters: AngebotsVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling angebotsVorlageHerunterladen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}/offerTemplate`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lädt die mit Preisen vorbefüllte Angebotsvorlage herunter.
     */
    async angebotsVorlageHerunterladen(requestParameters: AngebotsVorlageHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.angebotsVorlageHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createContractRaw(requestParameters: CreateContractOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Contract>> {
        if (requestParameters.createContractRequest === null || requestParameters.createContractRequest === undefined) {
            throw new runtime.RequiredError('createContractRequest','Required parameter requestParameters.createContractRequest was null or undefined when calling createContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContractRequestToJSON(requestParameters.createContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractFromJSON(jsonValue));
    }

    /**
     */
    async createContract(requestParameters: CreateContractOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Contract> {
        const response = await this.createContractRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteContractMachineGroupsRaw(requestParameters: DeleteContractMachineGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling deleteContractMachineGroups.');
        }

        if (requestParameters.machineGroupId === null || requestParameters.machineGroupId === undefined) {
            throw new runtime.RequiredError('machineGroupId','Required parameter requestParameters.machineGroupId was null or undefined when calling deleteContractMachineGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}/machinegroups/{machineGroupId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))).replace(`{${"machineGroupId"}}`, encodeURIComponent(String(requestParameters.machineGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteContractMachineGroups(requestParameters: DeleteContractMachineGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContractMachineGroupsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCompanyContractsRaw(requestParameters: GetCompanyContractsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Contract>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyContracts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}/contracts`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractFromJSON));
    }

    /**
     */
    async getCompanyContracts(requestParameters: GetCompanyContractsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Contract>> {
        const response = await this.getCompanyContractsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getContractRaw(requestParameters: GetContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Contract>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling getContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractFromJSON(jsonValue));
    }

    /**
     */
    async getContract(requestParameters: GetContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Contract> {
        const response = await this.getContractRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getContractMachineGroupsRaw(requestParameters: GetContractMachineGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetContractMachineGroups200ResponseInner>>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling getContractMachineGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}/machinegroups`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetContractMachineGroups200ResponseInnerFromJSON));
    }

    /**
     */
    async getContractMachineGroups(requestParameters: GetContractMachineGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetContractMachineGroups200ResponseInner>> {
        const response = await this.getContractMachineGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getContractsRaw(requestParameters: GetContractsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Contract>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractFromJSON));
    }

    /**
     */
    async getContracts(requestParameters: GetContractsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Contract>> {
        const response = await this.getContractsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putContractRaw(requestParameters: PutContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Contract>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling putContract.');
        }

        if (requestParameters.contract === null || requestParameters.contract === undefined) {
            throw new runtime.RequiredError('contract','Required parameter requestParameters.contract was null or undefined when calling putContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContractToJSON(requestParameters.contract),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractFromJSON(jsonValue));
    }

    /**
     */
    async putContract(requestParameters: PutContractRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Contract> {
        const response = await this.putContractRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putContractMachineGroupsRaw(requestParameters: PutContractMachineGroupsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling putContractMachineGroups.');
        }

        if (requestParameters.machineGroupId === null || requestParameters.machineGroupId === undefined) {
            throw new runtime.RequiredError('machineGroupId','Required parameter requestParameters.machineGroupId was null or undefined when calling putContractMachineGroups.');
        }

        if (requestParameters.putContractMachineGroupsRequest === null || requestParameters.putContractMachineGroupsRequest === undefined) {
            throw new runtime.RequiredError('putContractMachineGroupsRequest','Required parameter requestParameters.putContractMachineGroupsRequest was null or undefined when calling putContractMachineGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/contracts/{contractId}/machinegroups/{machineGroupId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))).replace(`{${"machineGroupId"}}`, encodeURIComponent(String(requestParameters.machineGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutContractMachineGroupsRequestToJSON(requestParameters.putContractMachineGroupsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putContractMachineGroups(requestParameters: PutContractMachineGroupsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putContractMachineGroupsRaw(requestParameters, initOverrides);
    }

}
