import { computed, Ref, ref } from "vue";
import { environment, Environment } from "./environment";

const developers = new Set(
  ["46e3754d-65e1-4c48-b7dc-5483e4dde4f1"].map(
    (a) => a + ".ad96ab3f-3d64-4455-8acb-2a4f7badc27f",
  ),
);
const homeAccountId = ref<string>();
const isDeveloper: Ref<boolean> = computed(() => {
  return (
    homeAccountId.value !== undefined && developers.has(homeAccountId.value)
  );
});

const showTestSystemWarning: Ref<boolean> = computed(() => {
  return (
    environment != Environment.PRODUCTION && !showProductionSystemWarning.value
  );
});
const showProductionSystemWarning: Ref<boolean> = computed(() => {
  return environment == Environment.PRODUCTION && isDeveloper.value;
});

export {
  isDeveloper,
  homeAccountId,
  showTestSystemWarning,
  showProductionSystemWarning,
};
