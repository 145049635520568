/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EigenschaftsOption } from './EigenschaftsOption';
import {
    EigenschaftsOptionFromJSON,
    EigenschaftsOptionFromJSONTyped,
    EigenschaftsOptionToJSON,
} from './EigenschaftsOption';

/**
 * 
 * @export
 * @interface EinreichenKonfiguration
 */
export interface EinreichenKonfiguration {
    /**
     * 
     * @type {EigenschaftsOption}
     * @memberof EinreichenKonfiguration
     */
    einsatzArt: EigenschaftsOption;
    /**
     * 
     * @type {EigenschaftsOption}
     * @memberof EinreichenKonfiguration
     */
    sofortAbnahme: EigenschaftsOption;
    /**
     * 
     * @type {EigenschaftsOption}
     * @memberof EinreichenKonfiguration
     */
    empfaengerExtern: EigenschaftsOption;
    /**
     * 
     * @type {EigenschaftsOption}
     * @memberof EinreichenKonfiguration
     */
    zusatzDokument: EigenschaftsOption;
    /**
     * 
     * @type {EigenschaftsOption}
     * @memberof EinreichenKonfiguration
     */
    externeUnterschrift: EigenschaftsOption;
}

/**
 * Check if a given object implements the EinreichenKonfiguration interface.
 */
export function instanceOfEinreichenKonfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "einsatzArt" in value;
    isInstance = isInstance && "sofortAbnahme" in value;
    isInstance = isInstance && "empfaengerExtern" in value;
    isInstance = isInstance && "zusatzDokument" in value;
    isInstance = isInstance && "externeUnterschrift" in value;

    return isInstance;
}

export function EinreichenKonfigurationFromJSON(json: any): EinreichenKonfiguration {
    return EinreichenKonfigurationFromJSONTyped(json, false);
}

export function EinreichenKonfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EinreichenKonfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'einsatzArt': EigenschaftsOptionFromJSON(json['EinsatzArt']),
        'sofortAbnahme': EigenschaftsOptionFromJSON(json['SofortAbnahme']),
        'empfaengerExtern': EigenschaftsOptionFromJSON(json['EmpfaengerExtern']),
        'zusatzDokument': EigenschaftsOptionFromJSON(json['ZusatzDokument']),
        'externeUnterschrift': EigenschaftsOptionFromJSON(json['ExterneUnterschrift']),
    };
}

export function EinreichenKonfigurationToJSON(value?: EinreichenKonfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EinsatzArt': EigenschaftsOptionToJSON(value.einsatzArt),
        'SofortAbnahme': EigenschaftsOptionToJSON(value.sofortAbnahme),
        'EmpfaengerExtern': EigenschaftsOptionToJSON(value.empfaengerExtern),
        'ZusatzDokument': EigenschaftsOptionToJSON(value.zusatzDokument),
        'ExterneUnterschrift': EigenschaftsOptionToJSON(value.externeUnterschrift),
    };
}

