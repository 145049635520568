/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonReportError
 */
export interface JsonReportError {
    /**
     * 
     * @type {number}
     * @memberof JsonReportError
     */
    einstufung: number;
    /**
     * 
     * @type {string}
     * @memberof JsonReportError
     */
    text: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonReportError
     */
    anmerkungen?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonReportError
     */
    rueckverfolgung: Array<string>;
}

/**
 * Check if a given object implements the JsonReportError interface.
 */
export function instanceOfJsonReportError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "einstufung" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "rueckverfolgung" in value;

    return isInstance;
}

export function JsonReportErrorFromJSON(json: any): JsonReportError {
    return JsonReportErrorFromJSONTyped(json, false);
}

export function JsonReportErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReportError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'einstufung': json['Einstufung'],
        'text': json['Text'],
        'anmerkungen': !exists(json, 'Anmerkungen') ? undefined : json['Anmerkungen'],
        'rueckverfolgung': json['Rueckverfolgung'],
    };
}

export function JsonReportErrorToJSON(value?: JsonReportError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Einstufung': value.einstufung,
        'Text': value.text,
        'Anmerkungen': value.anmerkungen,
        'Rueckverfolgung': value.rueckverfolgung,
    };
}

