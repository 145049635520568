/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Beinhaltet Verfügbarkeit von Dateien.
 * @export
 * @interface EintragDateien
 */
export interface EintragDateien {
    /**
     * 
     * @type {boolean}
     * @memberof EintragDateien
     */
    rvcSicherung?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EintragDateien
     */
    berichtEingereicht?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EintragDateien
     */
    berichtExternEmpfangen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EintragDateien
     */
    abnahmeprotokoll?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EintragDateien
     */
    rechnungIntern?: boolean;
}

/**
 * Check if a given object implements the EintragDateien interface.
 */
export function instanceOfEintragDateien(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EintragDateienFromJSON(json: any): EintragDateien {
    return EintragDateienFromJSONTyped(json, false);
}

export function EintragDateienFromJSONTyped(json: any, ignoreDiscriminator: boolean): EintragDateien {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rvcSicherung': !exists(json, 'RvcSicherung') ? undefined : json['RvcSicherung'],
        'berichtEingereicht': !exists(json, 'BerichtEingereicht') ? undefined : json['BerichtEingereicht'],
        'berichtExternEmpfangen': !exists(json, 'BerichtExternEmpfangen') ? undefined : json['BerichtExternEmpfangen'],
        'abnahmeprotokoll': !exists(json, 'Abnahmeprotokoll') ? undefined : json['Abnahmeprotokoll'],
        'rechnungIntern': !exists(json, 'RechnungIntern') ? undefined : json['RechnungIntern'],
    };
}

export function EintragDateienToJSON(value?: EintragDateien | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RvcSicherung': value.rvcSicherung,
        'BerichtEingereicht': value.berichtEingereicht,
        'BerichtExternEmpfangen': value.berichtExternEmpfangen,
        'Abnahmeprotokoll': value.abnahmeprotokoll,
        'RechnungIntern': value.rechnungIntern,
    };
}

