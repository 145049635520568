/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';
import type { StateTransition } from './StateTransition';
import {
    StateTransitionFromJSON,
    StateTransitionFromJSONTyped,
    StateTransitionToJSON,
} from './StateTransition';

/**
 * 
 * @export
 * @interface BerichtszustandGraph
 */
export interface BerichtszustandGraph {
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof BerichtszustandGraph
     */
    startzustand: BerichtsStatus;
    /**
     * 
     * @type {Array<BerichtsStatus>}
     * @memberof BerichtszustandGraph
     */
    zustnde: Array<BerichtsStatus>;
    /**
     * 
     * @type {Array<StateTransition>}
     * @memberof BerichtszustandGraph
     */
    zustandsbergnge: Array<StateTransition>;
}

/**
 * Check if a given object implements the BerichtszustandGraph interface.
 */
export function instanceOfBerichtszustandGraph(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startzustand" in value;
    isInstance = isInstance && "zustnde" in value;
    isInstance = isInstance && "zustandsbergnge" in value;

    return isInstance;
}

export function BerichtszustandGraphFromJSON(json: any): BerichtszustandGraph {
    return BerichtszustandGraphFromJSONTyped(json, false);
}

export function BerichtszustandGraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtszustandGraph {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startzustand': BerichtsStatusFromJSON(json['Startzustand']),
        'zustnde': ((json['Zustände'] as Array<any>).map(BerichtsStatusFromJSON)),
        'zustandsbergnge': ((json['Zustandsübergänge'] as Array<any>).map(StateTransitionFromJSON)),
    };
}

export function BerichtszustandGraphToJSON(value?: BerichtszustandGraph | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Startzustand': BerichtsStatusToJSON(value.startzustand),
        'Zustände': ((value.zustnde as Array<any>).map(BerichtsStatusToJSON)),
        'Zustandsübergänge': ((value.zustandsbergnge as Array<any>).map(StateTransitionToJSON)),
    };
}

