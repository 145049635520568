/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportPdfResponseBodyBerichtDaten } from './ReportPdfResponseBodyBerichtDaten';
import {
    ReportPdfResponseBodyBerichtDatenFromJSON,
    ReportPdfResponseBodyBerichtDatenFromJSONTyped,
    ReportPdfResponseBodyBerichtDatenToJSON,
} from './ReportPdfResponseBodyBerichtDaten';

/**
 * 
 * @export
 * @interface ReportPdfResponseBody
 */
export interface ReportPdfResponseBody {
    /**
     * 
     * @type {string}
     * @memberof ReportPdfResponseBody
     */
    berichtPdf: string;
    /**
     * 
     * @type {ReportPdfResponseBodyBerichtDaten}
     * @memberof ReportPdfResponseBody
     */
    berichtDaten: ReportPdfResponseBodyBerichtDaten;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportPdfResponseBody
     */
    fehler?: Array<string>;
    /**
     * Signatur für die hochgeladene ZIP-Datei und das bearbeitete Pdf. Muss zum Einreichen angegeben werden.
     * @type {string}
     * @memberof ReportPdfResponseBody
     */
    signatur: string;
}

/**
 * Check if a given object implements the ReportPdfResponseBody interface.
 */
export function instanceOfReportPdfResponseBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtPdf" in value;
    isInstance = isInstance && "berichtDaten" in value;
    isInstance = isInstance && "signatur" in value;

    return isInstance;
}

export function ReportPdfResponseBodyFromJSON(json: any): ReportPdfResponseBody {
    return ReportPdfResponseBodyFromJSONTyped(json, false);
}

export function ReportPdfResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPdfResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtPdf': json['BerichtPdf'],
        'berichtDaten': ReportPdfResponseBodyBerichtDatenFromJSON(json['BerichtDaten']),
        'fehler': !exists(json, 'Fehler') ? undefined : json['Fehler'],
        'signatur': json['Signatur'],
    };
}

export function ReportPdfResponseBodyToJSON(value?: ReportPdfResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtPdf': value.berichtPdf,
        'BerichtDaten': ReportPdfResponseBodyBerichtDatenToJSON(value.berichtDaten),
        'Fehler': value.fehler,
        'Signatur': value.signatur,
    };
}

